import * as React from 'react';

function SvgComponent() {
    return (
        <svg width='12' height='18'>
            <path
                fillRule='evenodd'
                d='M5.79 17.999c-3.198 0-5.79-2.591-5.79-5.786 0-1.571.625-2.996 1.642-4.039.573-.589 1.267-1.054 2.044-1.356.652-.255 1.363-.394 2.104-.394.742 0 1.453.139 2.105.394.776.304 1.471.769 2.043 1.356 1.016 1.043 1.643 2.468 1.643 4.039 0 3.197-2.593 5.786-5.791 5.786zm-.036-8.406c-.791.018-1.434.661-1.455 1.452-.003.1.006.198.022.292.074.425.325.786.676 1.006.146.092.217.264.182.431l-.443 2.101c-.026.129.069.247.2.247h1.709c.13 0 .227-.12.2-.247l-.444-2.101c-.036-.165.037-.337.182-.429.352-.22.603-.583.676-1.006.015-.082.023-.168.023-.256 0-.835-.689-1.511-1.528-1.49zm2.143-3.641V4.343c0-1.143-.93-2.072-2.074-2.072H5.76c-1.145 0-2.074.929-2.074 2.072V5.95c-.158.053-.315.112-.468.178-.57.24-1.097.557-1.576.943V4.343C1.642 2.074 3.49.228 5.758.228h.063c2.27 0 4.117 1.846 4.117 4.115v2.728c-.477-.386-1.005-.703-1.575-.943-.153-.066-.311-.125-.466-.176z'
            />
        </svg>
    );
}

export default SvgComponent;
