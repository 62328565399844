import { Layout } from 'antd';
import { Header, Footer } from 'components';

const { Content } = Layout;

const DefaultLayout = ({ children }: IDefaultLayoutProps) => {
    return (
        <Layout>
            <Header />
            <Content>{children}</Content>
            <Footer />
        </Layout>
    );
};

export default DefaultLayout;
