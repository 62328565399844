import { Container, Table } from './style';

const UserTable = ({ columns, dataSource, pagination }: IUserTableProps) => {
    return (
        <Container>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={pagination}
            />
        </Container>
    );
};

export default UserTable;
