import Axios, { AxiosError } from 'axios';
import { IAlertPayload } from 'core/Alert';
import { CookieService, ECookieVariable } from 'services';
import { ObjectUtil } from 'utils';

export class RequestError extends Error {
    status: number;
    message: string;
    errors: any;
    error: any;
    alert: IAlertPayload;

    constructor(error: AxiosError) {
        super(error as any);
        this.message = ObjectUtil.getIn(
            error,
            'response.data.message',
            'Unknown error from the system',
            (message: string) => message,
        );
        this.errors = ObjectUtil.getIn(error, 'response.data.errors');
        this.status = ObjectUtil.getIn(error, 'response.status', 3001);

        console.log(this.message);

        console.log(error.response);

        // Handle axios error
        if (error.code === 'ECONNABORTED' || error.message === 'Network Error')
            this.message = 'Network Error';
        else if (error.response && typeof error.response.data === 'string')
            this.message = error.response.data;

        this.error = {
            message: this.message,
            errors: this.errors,
            status: this.status,
        };

        this.alert = {
            message: this.message,
            type: 'danger',
        };
    }
}

export default class RequestService {
    getURL(subURL: string) {
        return `https://your_api${subURL}`;
    }

    getConfigs(params = {}) {
        const token = new CookieService().get(
            ECookieVariable.USER_ACCESS_TOKEN,
        );
        return {
            params: Object.assign(ObjectUtil.cleanObj(params), {}),
            timeout: 20000,
            headers: ObjectUtil.cleanObj({
                authorization: `Bearer ${token}`,
            }),
        };
    }

    async get(subURL: string, params = {}) {
        return Axios.get(this.getURL(subURL), this.getConfigs(params))
            .then(res => res.data)
            .catch(err => {
                throw new RequestError(err);
            });
    }

    async post(subURL: string, payload = {}) {
        return Axios.post(this.getURL(subURL), payload, this.getConfigs())
            .then(res => res.data)
            .catch(err => {
                throw new RequestError(err);
            });
    }

    async put(subURL: string, payload = {}) {
        return Axios.put(this.getURL(subURL), payload, this.getConfigs())
            .then(res => res.data)
            .catch(err => {
                throw new RequestError(err);
            });
    }

    async delete(subURL: string) {
        return Axios.delete(this.getURL(subURL), this.getConfigs())
            .then(res => res.data)
            .catch(err => {
                throw new RequestError(err);
            });
    }
}
