import * as React from 'react';

function SvgComponent() {
    return (
        <svg width='23' height='23'>
            <path
                fillRule='evenodd'
                d='M20.934 22.846v-2.078h2.079v2.078h-2.079zm-4.156-6.234H14.7v6.234h-2.078V12.455h6.235v2.078h2.078v-2.078h2.078v6.235h-6.235v-2.078zM12.622-.013h10.391v10.391H12.622V-.013zM14.7 8.299h6.235V2.065H14.7v6.234zm2.078-4.156h2.079v2.078h-2.079V4.143zM.153 12.456h10.391v10.39H.153v-10.39zm2.078 8.296h6.234v-6.219H2.231v6.219zm2.078-4.14h2.078v2.078H4.309v-2.078zM.153-.013h10.391v10.391H.153V-.013zm2.078 8.312h6.234V2.065H2.231v6.234zm2.078-4.156h2.078v2.078H4.309V4.143zm14.548 18.703h-2.079v-2.078h2.079v2.078z'
            />
        </svg>
    );
}

export default SvgComponent;
