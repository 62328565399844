import styled from 'styled-components';
import { Layout as AntLayout, Typography } from 'antd';

export const Layout = styled((props: any) => <AntLayout {...props} />)`
    background: transparent;
`;

export const Header = styled(AntLayout.Header)`
    height: 220px;
    background-color: #ffcb00;

    && ${Layout} {
        max-width: 1300px;
        margin: 0 auto;
    }
`;

export const Content = styled((props: any) => <AntLayout.Content {...props} />)`
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 35px;
`;

export const HeadLine = styled((props: any) => <Typography.Title {...props} />)`
    && {
        margin-bottom: 0;
        font-size: 43px;
    }
`;

export const Description = styled(Typography.Text)`
    color: #111111;
    font-size: 26px;
    line-height: 30px;
`;
