import { withBackground } from 'hoc';
import { Form } from 'components';
import url from 'assets/images/space-background.png';

const LoginForm = withBackground(Form.Login, url);
const LoginPage = (props: any) => {
    return <LoginForm {...props} />;
};

export default LoginPage;
