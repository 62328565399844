import * as React from 'react';

function SvgComponent() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='17' height='18'>
            <path
                fillRule='evenodd'
                d='M15.612 17.623H1.356c-.281 0-.509-.228-.509-.509 0-1.771.687-3.444 1.935-4.711 1.255-1.274 2.918-1.976 4.683-1.976h2.037c1.765 0 3.428.702 4.683 1.976 1.248 1.267 1.935 2.94 1.935 4.711 0 .281-.227.509-.508.509zM8.483 9.409c-2.526 0-4.582-2.055-4.582-4.582C3.901 2.3 5.957.245 8.483.245c2.527 0 4.582 2.055 4.582 4.582 0 2.527-2.055 4.582-4.582 4.582z'
            />
        </svg>
    );
}

export default SvgComponent;
