const radius: ISpacing = {
    mobile: {
        bodyPadding: '16px',
        buttonPadding: '0 32px',
        inputPadding: '4px 16px',
        cardPadding: '16px',
        star: '8px',
        carouselArrow: '24px',
    },
    desktop: {
        bodyPadding: '32px',
        buttonPadding: '0 32px',
        inputPadding: '4px 16px',
        cardPadding: '24px',
        star: '16px',
        carouselArrow: '64px',
    },
};

export default radius;
