const color: IColor = {
    white: '#FFFFFF',
    background: '#F9FAFF',
    backgroundWhite: '#F9FAFF',
    mainBlue: '#0D507A',
    mediumBlue: '#0976BB',
    brightBlue: '#7ED0FC',
    datePicker: '#10527A',
    fontBlue: '#1E3056',
    iceBlue: '#C8EDFF',
    blue: '#7ED0FC',
    green: '#37D8AB',
    // https://www.rapidtables.com/web/css/css-color.html
    mediumSpringGreen: '#17A9B5',
    yellow: '#FFFA9F',
    warning: '#FF9D6F',
    red: '#FF9085',
    error: '#B5173B',
    lightGrey: '#DDDDDD',
    grey: '#B1B1B1',
    darkGrey: '#797979',
    orange: '#E04937',
    black: '#000000',
};

export default color;
