import styled from 'styled-components';
import { Layout, Image as AntImage, List, Tabs as AntTabs, Space } from 'antd';

const textStyle = {
    fontSize: '18.5px',
    fontWeight: 500,
};

export const Content = styled(Layout.Content)`
    ${textStyle};
    width: 100%;
    padding: 43px 23px 54px 25px;
    border-radius: 40px;
    box-shadow: 0px 5px 21px 0 rgba(184, 159, 59, 0.15);
    background-color: #fff;
`;

export const Tabs = styled((props: any) => <AntTabs {...props} />)`
    && {
        .ant-tabs-nav-wrap {
            margin-bottom: 10px;
        }

        .ant-tabs-nav::before {
            border-bottom: 1px solid #eeeeee;
        }

        .ant-tabs-tab {
            background: transparent;
            border: transparent;
            color: #7b7b7b;
            font-size: 26px;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #b18e06;
            font-weight: 500;
        }
    }
`;

export const TabPane = styled((props: any) => <AntTabs.TabPane {...props} />)`
    && {
        .ant-tabs-tab {
            background: transparent;
        }

        .ant-tabs-tab-btn {
            color: red;
        }
    }
`;

export const ProductItem = styled((props: any) => <List.Item {...props} />)``;

export const ProductImage = styled((props: any) => (
    <AntImage {...props} preview={false} />
))`
    width: 234px;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
    background-color: #d0d0d0;
    margin-right: 20px;
`;

export const ProductContent = styled((props: any) => (
    <Space {...props} size='middle' />
));
