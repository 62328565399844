import * as React from 'react';

function SvgComponent() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13'>
            <path
                fillRule='evenodd'
                d='M12.01 12.999H8.278c-.546 0-.99-.443-.99-.989V8.277c0-.545.444-.989.99-.989h3.732c.545 0 .99.443.99.989v3.733c0 .545-.445.989-.99.989zm0-7.288H8.278c-.546 0-.99-.444-.99-.99V.989c0-.546.444-.989.99-.989h3.732c.545 0 .99.443.99.989v3.732c0 .546-.445.99-.99.99zm-7.288 7.288H.989c-.546 0-.989-.444-.989-.989V8.277c0-.546.443-.989.989-.989h3.733c.546 0 .989.443.989.989v3.733c0 .545-.443.989-.989.989zm0-7.288H.989c-.546 0-.989-.444-.989-.99V.989C0 .443.443 0 .989 0h3.733c.546 0 .989.443.989.989v3.732c0 .546-.443.99-.989.99z'
            />
        </svg>
    );
}

export default SvgComponent;
