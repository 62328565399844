import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox='0 0 27 24'
            xmlns='http://www.w3.org/2000/svg'
            width='1em'
            height='1em'
            {...props}
        >
            <path
                d='M14.149.465l12.283 21.274c.18.31.18.69 0 1a.997.997 0 01-.865.5H1a.998.998 0 01-.866-1.5L12.417.465c.357-.62 1.374-.62 1.732 0zm-.865 2.5L2.732 21.239h21.102L13.284 2.965zM13.15 16.54c.287 0 .534.1.735.297a.972.972 0 01.304.722c0 .319-.1.569-.306.752a1.051 1.051 0 01-.719.273c-.277 0-.52-.09-.729-.271-.207-.179-.31-.431-.31-.754 0-.283.1-.523.297-.722a.996.996 0 01.728-.297zm.055-8.306c.393 0 .654.136.787.408.131.27.197.661.197 1.171 0 .301-.015.607-.046.917l-.295 3.37c-.032.401-.1.709-.205.922a.538.538 0 01-.52.322c-.246 0-.416-.104-.512-.312-.095-.207-.164-.522-.205-.946l-.219-3.274a24.512 24.512 0 01-.062-1.374c0-.378.1-.673.297-.886.2-.211.461-.318.783-.318z'
                fill='#F2EEE8'
            />
        </svg>
    );
}

export default SvgComponent;
