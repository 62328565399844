import styled from 'styled-components';
import { Table as AntdTable, TableProps } from 'antd';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Table = styled((props: TableProps<any>) => (
    <AntdTable {...props} />
))`
    && {
        .ant-table {
            box-shadow: 0px 8px 7.6px 1.4px rgba(123, 124, 1, 0.08);
            border-radius: 20px;
            .ant-table-container {
                .ant-table-content {
                    table {
                        .ant-table-thead {
                            tr {
                                &:first-child {
                                    .ant-table-cell {
                                        background: #fff;
                                        font-size: 20px;
                                        font-weight: 500;
                                        color: #000000;
                                        &:before {
                                            content: none;
                                        }
                                        &:first-child {
                                            border-top-left-radius: 20px;
                                        }
                                        &:last-child {
                                            border-top-right-radius: 20px;
                                        }
                                    }
                                }
                            }
                        }
                        .ant-table-tbody {
                            .ant-table-cell {
                                font-size: 20px;
                                color: #6c757d;
                            }
                        }
                    }
                }
            }
        }
    }
`;
