import { Layout, LayoutProps } from 'antd';
import styled from 'styled-components';
import { color } from 'themes';
import { Link, LinkProps } from 'react-router-dom';

const { Footer: AntdFooter } = Layout;

export const Container = styled((props: LayoutProps) => (
    <AntdFooter {...props} />
))`
    && {
        background: ${color.black};
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 0;
    }
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1304px;
    width: 100%;
    margin: 0 auto;
`;

export const InnerList = styled.div`
    display: flex;
    padding: 50px 0;
`;

export const Organization = styled.div`
    flex-basis: 328px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    margin-right: 82px;
`;

export const Logo = styled.div`
    margin-bottom: 32px;
`;

export const Text = styled.div`
    color: ${color.white};
    font-size: 18px;
    margin-bottom: 8px;
    > a {
        color: ${color.white};
    }
`;

export const Copyright = styled.div`
    ${Text} {
        font-size: 14px;
    }
`;

export const Menu = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
`;

export const MenuCol = styled.div`
    flex-basis: 198px;
    &:last-child {
        margin-right: 0;
    }
`;

export const MenuLabel = styled.div`
    font-size: 18.5px;
    color: #ffca00;
    font-weight: 500;
    margin-bottom: 30px;
`;

export const MenuListItem = styled.div`
    font-size: 18px;
    font-weight: 300;
    color: ${color.white};
`;

export const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    ${MenuListItem} {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const StyledLink = styled((props: LinkProps) => <Link {...props} />)`
    color: ${color.white};
`;
