import { useState } from 'react';
import {
    Container,
    StyledRadioGroup,
    StyledRadioButton,
    Content,
    StyledInput,
    StyledFormItem,
    FormWrapper,
    LogoWrapper,
} from './style';
import { Alert, Form } from 'antd';
import {
    UserOutlined,
    LockOutlined,
    MailOutlined,
    SolutionOutlined,
} from '@ant-design/icons';
import { Button, Icons } from 'core';
import { useAppDispatch } from 'store';
import {
    ActionType,
    authSelector,
    login,
    onChangeFieldValues,
    register,
    StatusType,
} from 'redux/features/authSlice';
import { useSelector } from 'react-redux';

type ModeType = 'register' | 'login';

const RegisterMode = () => {
    const dispatch = useAppDispatch();
    const authState = useSelector(authSelector);

    const isLoading =
        authState.action === ActionType.Register &&
        authState.status === StatusType.Pending;
    const isError =
        authState.action === ActionType.Register &&
        authState.status === StatusType.Error;

    const isLogInSuccess =
        authState.action === ActionType.Login &&
        authState.status === StatusType.Success;

    if (isLogInSuccess) {
        window.location.reload();
    }

    const onFinish = (values: any) => {
        dispatch(register(values));
    };

    return (
        <Form
            name='register'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onChange={() => dispatch(onChangeFieldValues())}
        >
            <StyledFormItem
                name='username'
                rules={[
                    {
                        required: true,
                        message: 'Tên đăng nhập không được để trống!',
                    },
                ]}
            >
                <StyledInput
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    placeholder='Tên đăng nhập'
                />
            </StyledFormItem>
            <StyledFormItem
                name='password'
                rules={[
                    {
                        required: true,
                        message: 'Mật khẩu không được để trống!',
                    },
                ]}
            >
                <StyledInput
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder='Mật khẩu'
                />
            </StyledFormItem>
            <StyledFormItem
                name='confirm'
                dependencies={['password']}
                rules={[
                    { required: true, message: 'Vui lòng nhập lại mật khẩu!' },
                    ({ getFieldValue }: any) => ({
                        validator(_: any, value: any) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error('Mật khẩu không khớp!'),
                            );
                        },
                    }),
                ]}
            >
                <StyledInput
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder='Nhập lại mật khẩu'
                />
            </StyledFormItem>
            <StyledFormItem
                name='email'
                rules={[{ required: true, message: 'Vui lòng nhập email!' }]}
            >
                <StyledInput
                    prefix={<MailOutlined className='site-form-item-icon' />}
                    placeholder='Email'
                />
            </StyledFormItem>
            <StyledFormItem
                name='fullName'
                rules={[{ required: true, message: 'Vui lòng nhập họ tên!' }]}
            >
                <StyledInput
                    prefix={
                        <SolutionOutlined className='site-form-item-icon' />
                    }
                    placeholder='Họ tên'
                />
            </StyledFormItem>
            {isError && (
                <Alert
                    type='error'
                    message={authState.error}
                    style={{ marginBottom: 24 }}
                />
            )}
            <Form.Item style={{ marginBottom: 12 }}>
                <Button.Primary
                    loading={isLoading}
                    style={{ borderRadius: 32 }}
                    htmlType='submit'
                >
                    Đăng ký
                </Button.Primary>
            </Form.Item>
        </Form>
    );
};

const LoginMode = () => {
    const dispatch = useAppDispatch();
    const authState = useSelector(authSelector);

    const isLoading =
        authState.action === ActionType.Login &&
        authState.status === StatusType.Pending;
    const isError =
        authState.action === ActionType.Login &&
        authState.status === StatusType.Error;

    const onFinish = (values: any) => {
        dispatch(login(values));
    };

    return (
        <Form
            name='login'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onChange={() => dispatch(onChangeFieldValues())}
        >
            <StyledFormItem
                name='username'
                rules={[
                    {
                        required: true,
                        message: 'Tên đăng nhập không được để trống!',
                    },
                ]}
            >
                <StyledInput
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    placeholder='Tên đăng nhập'
                />
            </StyledFormItem>
            <StyledFormItem
                name='password'
                rules={[
                    {
                        required: true,
                        message: 'Mật khẩu không được để trống!',
                    },
                ]}
            >
                <StyledInput
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder='Mật khẩu'
                />
            </StyledFormItem>
            {isError && (
                <Alert
                    type='error'
                    message={authState.error}
                    style={{ marginBottom: 24 }}
                />
            )}
            <Form.Item style={{ marginBottom: 12 }}>
                <Button.Primary
                    loading={isLoading}
                    style={{ borderRadius: 32 }}
                    htmlType='submit'
                >
                    Đăng nhập
                </Button.Primary>
            </Form.Item>
        </Form>
    );
};

const Register = () => {
    const [mode, setMode] = useState<ModeType>('register');

    const renderMode = () => {
        switch (mode) {
            case 'login':
                return <LoginMode />;
            case 'register':
                return <RegisterMode />;
        }
    };

    return (
        <Container>
            <LogoWrapper>
                <Icons.Logo />
            </LogoWrapper>
            <FormWrapper>
                <StyledRadioGroup
                    value={mode}
                    onChange={(e: any) => setMode(e.target.value)}
                >
                    <StyledRadioButton value='register'>
                        Đăng ký
                    </StyledRadioButton>
                    <StyledRadioButton value='login'>
                        Đăng nhập
                    </StyledRadioButton>
                </StyledRadioGroup>
                <Content>{renderMode()}</Content>
            </FormWrapper>
        </Container>
    );
};

export default Register;
