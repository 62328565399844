import styled from 'styled-components';

export const Container = styled.header`
    background: #12161c;
`;

export const Inner = styled.div`
    display: flex;
    max-width: 1304px;
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
`;

export const Logo = styled.div`
    display: flex;
    flex-basis: 195px;
    margin-right: 20px;
`;
