import Desktop from './Desktop';
import { ResponsiveLayout } from 'core';

const BarChartTable = (props: IBarChartTableProps) => {
    return (
        <ResponsiveLayout
            desktopView={<Desktop {...props} />}
            mobileView={<Desktop {...props} />}
        />
    );
};

export default BarChartTable;
