import { DashboardLayout } from 'layouts';
import styled, { CSSProperties } from 'styled-components';
import { Headline, List, Table } from 'components';
import { Typography, Tag } from 'antd';
import { getCommaNumber } from 'utils/numeral';
import mkaIcon from '../../assets/images/icon-mka.png';
import vndcIcon from '../../assets/images/icon-vndc.png';
import kyQuyIcon from '../../assets/images/icon-ky-quy.png';
const { Text } = Typography;

const AssetCardsListWrapper = styled.div``;

const Statistic = styled.div`
    display: flex;
    margin-top: 20px;
`;

const StatisticCardListWrapper = styled.div`
    width: 33.33%;
    margin-right: 20px;
`;

const StatisticTable = styled.div`
    width: 66.67%;
`;

const TransactionHistoryWrapper = styled.div`
    margin: 40px 0;
`;

const TagStyles: CSSProperties = {
    fontSize: 20,
    height: 'auto',
    padding: '8px 14px',
    borderRadius: 6,
    minWidth: 134,
    textAlign: 'center',
};

const tableData = [
    {
        name: 'MON',
        uv: 4000,
        pv: 2400,
    },
    {
        name: 'TUE',
        uv: 3000,
        pv: 1398,
    },
    {
        name: 'WED',
        uv: 2000,
        pv: 9800,
    },
    {
        name: 'THU',
        uv: 2780,
        pv: 3908,
    },
    {
        name: 'FRI',
        uv: 1890,
        pv: 4800,
    },
    {
        name: 'SAT',
        uv: 2390,
        pv: 3800,
    },
    {
        name: 'SUN',
        uv: 3490,
        pv: 4300,
    },
];

const data = [
    {
        name: 'MON',
        uv: 4000,
    },
    {
        name: 'TUE',
        uv: 3000,
    },
    {
        name: 'WED',
        uv: 2000,
    },
    {
        name: 'THU',
        uv: 2780,
    },
    {
        name: 'FRI',
        uv: 1890,
    },
    {
        name: 'SAT',
        uv: 2390,
    },
    {
        name: 'SUN',
        uv: 3490,
    },
];

const assetCards: IListAssetCardsProps = {
    cards: [
        {
            name: 'VND',
            amount: 50000000,
            icon: mkaIcon,
        },
        {
            name: 'VNDC',
            amount: 60878778,
            icon: vndcIcon,
        },
        {
            name: 'Ký quỹ',
            amount: 5000000,
            icon: kyQuyIcon,
        },
    ],
};

const statisticsCards: IListStatisticsCardsProps = {
    direction: 'vertical',
    cards: [
        {
            name: 'Tổng đơn hàng',
            amount: 50,
            chart: {
                data: data,
                dataKey: 'uv',
            },
            status: 'down',
            rate: '2.2%',
        },
        {
            name: 'Tổng chi tiêu',
            amount: '$5,234.21',
            chart: {
                data: data,
                dataKey: 'uv',
            },
            status: 'up',
            rate: '2.2%',
        },
        {
            name: 'Khách hàng mới',
            amount: 264,
            chart: {
                data: data,
                dataKey: 'uv',
            },
            status: 'up',
            rate: '2.2%',
        },
    ],
};

const barCharTable: IBarChartTableProps = {
    title: 'Tóm tắt chi tiêu',
    chart: {
        data: tableData,
        bars: [
            {
                name: 'Tuần này',
                dataKey: 'pv',
                fill: '#8884d8',
            },
            {
                name: 'Tuần trước',
                dataKey: 'uv',
                fill: '#82ca9d',
            },
        ],
    },
};

interface ITransactionProps {
    id: string;
    created: string;
    name: string;
    amount: number;
    benefit: string;
    status: 'success' | 'pending' | 'canceled';
}

const transactions: ITransactionProps[] = [
    {
        id: '1',
        name: 'Nguyễn Minh Trí',
        created: '24/05/2021 17h:10:20',
        amount: 500000000,
        benefit: '20%',
        status: 'success',
    },
    {
        id: '2',
        name: 'Nguyễn Minh Trí',
        created: '24/05/2021 17h:10:20',
        amount: 200000000,
        benefit: '10%',
        status: 'pending',
    },
    {
        id: '3',
        name: 'Nguyễn Minh Trí',
        created: '24/05/2021 17h:10:20',
        amount: 500000000,
        benefit: '50%',
        status: 'canceled',
    },
    {
        id: '4',
        name: 'Nguyễn Minh Trí',
        created: '24/05/2021 17h:10:20',
        amount: 500000000,
        benefit: '20%',
        status: 'success',
    },
    {
        id: '5',
        name: 'Nguyễn Minh Trí',
        created: '24/05/2021 17h:10:20',
        amount: 200000000,
        benefit: '10%',
        status: 'pending',
    },
    {
        id: '6',
        name: 'Nguyễn Minh Trí',
        created: '24/05/2021 17h:10:20',
        amount: 500000000,
        benefit: '50%',
        status: 'canceled',
    },
];

const SuccessTag = () => (
    <Tag
        color='#e3f6eb'
        style={{
            ...TagStyles,
            color: '#044b22',
        }}
    >
        Thành công
    </Tag>
);

const PendingTag = () => (
    <Tag
        color='#fff1c7'
        style={{
            ...TagStyles,
            color: '#9f7400',
        }}
    >
        Chờ duyệt
    </Tag>
);

const CanceledTag = () => (
    <Tag
        color='#ffcccc'
        style={{
            ...TagStyles,
            color: '#8c1212',
        }}
    >
        Đã hủy
    </Tag>
);

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: 'Thời gian',
        dataIndex: 'created',
    },
    {
        title: 'Tên',
        dataIndex: 'name',
    },
    {
        title: 'Số tiền',
        dataIndex: 'amount',
        // eslint-disable-next-line react/display-name
        render: (amount: number) => (
            <Text style={{ color: '#e76200' }}>{getCommaNumber(amount)}</Text>
        ),
    },
    {
        title: 'Lợi ích',
        dataIndex: 'benefit',
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        // eslint-disable-next-line react/display-name
        render: (status: 'success' | 'pending' | 'canceled') => {
            switch (status) {
                case 'success':
                    return <SuccessTag />;
                case 'pending':
                    return <PendingTag />;
                case 'canceled':
                    return <CanceledTag />;
            }
        },
    },
];

const Dashboard = () => {
    return (
        <DashboardLayout>
            <AssetCardsListWrapper>
                <List.AssetCards {...assetCards} />
            </AssetCardsListWrapper>
            <Statistic>
                <StatisticCardListWrapper>
                    <List.StatisticsCards {...statisticsCards} />
                </StatisticCardListWrapper>
                <StatisticTable>
                    <Table.BarChart {...barCharTable} />
                </StatisticTable>
            </Statistic>
            <TransactionHistoryWrapper>
                <Headline>Lịch sử giao dịch</Headline>
                <Table.Transaction
                    dataSource={transactions}
                    columns={columns}
                />
            </TransactionHistoryWrapper>
        </DashboardLayout>
    );
};

export default Dashboard;
