import { Menu, Typography, Avatar as AntAvatar, Layout } from 'antd';
import { color } from 'themes';
import styled from 'styled-components';

const textStyle = {
    fontSize: '17.5px',
};

const iconStyle = {
    marginRight: '18px',
    width: '18px',
};

export const Sider = styled((props: any) => <Layout.Sider {...props} />)`
    background: ${color.white};
    border-radius: 60px;
    box-shadow: 0px 5px 21px 0 rgba(184, 159, 59, 0.15);
`;

export const Summary = styled.div`
    border-radius: 60px;
    background-color: #fff4c9;
    text-align: center;
`;

export const Avatar = styled((props: any) => <AntAvatar {...props} />)`
    margin: 25px auto 15px auto;
`;

export const Name = styled((props: any) => <Typography.Title {...props} />)`
    && {
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 5px;
    }
`;

export const Country = styled((props: any) => <Typography.Text {...props} />)`
    && {
        ${textStyle}
    }
`;

export const MemberBlock = styled.div`
    ${textStyle};
    border-top: solid 1px rgb(17 17 17 / 11%);
    padding: 9px 0 20px 0;
    margin-top: 20px;
`;

export const MemberType = styled((props: any) => (
    <Typography.Text {...props} />
))`
    mark {
        background-color: ${props => props.markColor || '#f1f1f1'};
        color: ${props => props.color || '#111111'};
        margin-left: 5px;
        padding: 6px 15px;
        border-radius: 10px;
    }
`;

export const MenuItem = styled((props: any) => <Menu.Item {...props} />)`
    border-bottom: 1px solid #eeeeee;

    svg {
        ${iconStyle};
        fill: ${props => (props.currentPage ? '#b08d06' : '#111111')};
    }

    span {
        ${textStyle};
        color: ${props => (props.currentPage ? '#b08d06' : '#111111')};
    }
`;
