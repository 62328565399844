import { Container, Table } from './style';

const TransactionTable = ({ columns, dataSource }: ITransactionTableProps) => {
    return (
        <Container>
            <Table dataSource={dataSource} columns={columns} />
        </Container>
    );
};

export default TransactionTable;
