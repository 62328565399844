export const HOME = '/';
export const NOT_FOUND = '/404/';
export const REGISTER = '/register/';
export const PROFILE = '/profile/';
export const LOGIN = '/login/';
export const ABOUT = '/about/';
export const CONTACT = '/contact/';
export const DASHBOARD = '/dashboard/';
export const TRANSACTION = '/transaction/';
export const USER = '/user/';
export const EMPLOYEE = '/employee/';
export const TEAM = '/team/';
export const SETTINGS = '/settings/';
export const PROPERTIES = '/properties/';
export const PROJECTS = '/projects/';

export default {
    [HOME]: HOME,
    [REGISTER]: REGISTER,
    [PROFILE]: PROFILE,
    [LOGIN]: LOGIN,
    [ABOUT]: ABOUT,
    [CONTACT]: CONTACT,
    [NOT_FOUND]: NOT_FOUND,
    [DASHBOARD]: DASHBOARD,
    [TRANSACTION]: TRANSACTION,
    [USER]: USER,
    [EMPLOYEE]: EMPLOYEE,
    [TEAM]: TEAM,
    [SETTINGS]: SETTINGS,
    [PROPERTIES]: PROPERTIES,
    [PROJECTS]: PROJECTS,
};
