import styled from 'styled-components';
import { Typography, Button, Layout, Space } from 'antd';

const textStyle = {
    fontSize: '18.5px',
    fontWeight: 500,
};

export const Content = styled(Layout.Content)`
    ${textStyle};
    width: 100%;
`;

export const AssetBlock = styled((props: any) => <Space {...props} />)`
    width: 100%;
`;

export const Asset = styled.div.attrs((props: any) => ({
    background: props.background || '#fff4c9',
}))`
    padding: 42px 28px 28px 28px;
    border-radius: 30px;
    box-shadow: 0px 8px 7.6px 1.4px rgba(123, 124, 1, 0.08);
    background-color: ${props => props.background};
    width: 300px;

    .ant-avatar {
        margin-right: 10px;
    }
`;

export const AssetValue = styled((props: any) => (
    <Typography.Text {...props} />
))`
    font-size: 26px;
    font-weight: bold;
    color: ${props => props.color || '#111111'};
    display: block;
`;

export const AssetName = styled((props: any) => <Typography.Text {...props} />)`
    margin-right: 10px;
`;

export const AddressCode = styled.div`
    padding: 18px 36px;
    border-radius: 20px;
    background-color: #f2f2f2;
    color: #666666;
    line-height: 1.3;

    span {
        font-weight: 600;
        color: #000;
        margin-left: 20px;
    }
`;

export const SVGButton = styled((props: any) => <Button {...props} />)`
    cursor: pointer;
    float: right;
    margin-left: 20px;
    border: none;
    width: fit-content;
    background-color: transparent !important;

    &:hover > svg {
        fill: '#fff';
    }
`;

export const HistoryBlock = styled.div`
    width: 100%;
    padding: 25px;
    border-radius: 40px;
    box-shadow: 0px 5px 21px 0 rgba(184, 159, 59, 0.15);
    background-color: white;
`;
