import React from 'react';
import { useAuthState } from 'hooks';
import { Role, Permission } from 'variables';
import { difference } from 'lodash';

export interface IUserContext {
    uuid: string | null;
    username: string | null;
    fullName: string | null;
    phone: string | null;
    email: string | null;
    walletAddress: string | null;
    role: Role;
    permissions: Permission[];
}

interface IAllowedTo {
    // A single permission or a list of permissions, if a list is provided all permissions are required.
    perform: Permission | Permission[];
    // The jsx element to render if permission is granted.
    yes?: React.ComponentType;
    // The jsx element to render if permission is not granted.
    no?: React.ComponentType;
    // The default element to render if permission is granted.
    children: React.ReactNode;
}

const initialContext: IUserContext = {
    uuid: null,
    username: null,
    fullName: null,
    phone: null,
    email: null,
    walletAddress: null,
    role: Role.User,
    permissions: [Permission.USER],
};

const UserContext = React.createContext<IUserContext>(initialContext);

function UserProvider(props: any) {
    return (
        <UserContext.Provider
            value={{ ...initialContext, ...useAuthState().user }}
            {...props}
        />
    );
}

const AllowedTo = ({
    perform = [],
    children,
    yes: Yes = () => <React.Fragment>{children}</React.Fragment>,
    no: No = () => null,
}: IAllowedTo) => {
    const user = React.useContext(UserContext);
    if (_checkPermission(perform, user.permissions)) {
        return <Yes />;
    }
    return <No />;
};

const _checkPermission = (
    perform: Permission | Permission[],
    permissions: Permission[],
): boolean => {
    if (typeof perform === 'string') {
        return permissions.includes(perform);
    }
    if (Array.isArray(perform)) {
        // all perform entries are into permissions
        return difference(perform, permissions).length === 0;
    }
    return false;
};

export default UserContext;
export { UserProvider, AllowedTo };
