import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            width='1em'
            height='1em'
            {...props}
        >
            <path
                d='M11.771 0c3.146 0 6.104 1.225 8.326 3.448 4.592 4.591 4.592 12.062.002 16.655a11.704 11.704 0 01-8.33 3.449 11.694 11.694 0 01-8.326-3.448c-4.592-4.592-4.59-12.064.002-16.656A11.701 11.701 0 0111.771 0zm0 2a9.715 9.715 0 00-6.912 2.862C1.046 8.674 1.046 14.877 4.857 18.69a9.71 9.71 0 006.912 2.862 9.718 9.718 0 006.916-2.864c3.811-3.812 3.811-10.014-.002-13.826A9.715 9.715 0 0011.771 2zm5.164 5.044a1 1 0 01.139 1.408l-5.725 6.98a.998.998 0 01-.736.365h-.037c-.273 0-.535-.112-.725-.31l-2.593-2.724a1 1 0 111.449-1.379l1.814 1.904 5.006-6.105c.35-.425.98-.49 1.408-.139z'
                fill='#F2EEE8'
            />
        </svg>
    );
}

export default SvgComponent;
