import { CookieService, ECookieVariable, RequestService } from 'services';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IUserContext } from 'contexts/UserContext';
import axios from 'axios';
import { RootState } from '../reducers/root';

export enum ActionType {
    Authenticate = 'AUTHENTICATE',
    Login = 'LOGIN',
    Register = 'REGISTER',
    Logout = 'LOGOUT',
    ChangeFieldValues = 'CHANGE_FIELD_VALUES',
}

export enum StatusType {
    Idle = 'IDLE',
    Pending = 'PENDING',
    Success = 'SUCCESS',
    Error = 'ERROR',
}

export interface AuthState {
    status: StatusType;
    error: string | null;
    user: IUserContext | null;
    isAuth: boolean;
    action: ActionType | null;
}

export interface IRegisterUserPayload {
    username: string;
    password: string;
    fullName: string;
    email: string;
}

export const initialState: AuthState = {
    isAuth: false,
    status: StatusType.Pending,
    error: null,
    user: null,
    action: ActionType.Authenticate,
};

export const checkAuth = createAsyncThunk(
    '/me',
    async (_, { rejectWithValue }) => {
        try {
            const token = new CookieService().get(
                ECookieVariable.USER_ACCESS_TOKEN,
            );
            if (token) {
                const user = await new RequestService().post('/me');
                return { isAuth: true, user };
            }
            return rejectWithValue({
                isAuth: false,
                user: null,
                error: 'Token is empty',
            });
        } catch (error) {
            return rejectWithValue({
                isAuth: false,
                user: null,
                error: 'Token is invalid',
            });
        }
    },
);

export const login = createAsyncThunk(
    '/login',
    async (
        payload: { username: string; password: string },
        { rejectWithValue },
    ) => {
        try {
            await new CookieService().set(
                ECookieVariable.USER_ACCESS_TOKEN,
                'random_string',
            );
            return { isAuth: true, user: null, error: null };
        } catch (error) {
            return rejectWithValue({
                isAuth: false,
                user: null,
                error: 'Tên tài khoản hoặc mật khẩu không chính xác!',
            });
        }
    },
);

export const logout = createAsyncThunk('identity/logout', async () => {
    // Remove access token and refresh access token from cookie
    new CookieService().remove(ECookieVariable.USER_ACCESS_TOKEN);
    // new CookieService().remove(ECookieVariable.USER_ACCESS_TOKEN_REFRESH);
    await new RequestService().post('/logout');
});

export const register = createAsyncThunk(
    '/register',
    async (payload: IRegisterUserPayload, { rejectWithValue }) => {
        try {
            const url = `/register`;
            const response = await axios.post(url, {
                username: payload.username,
                email: payload.email,
                fullName: payload.fullName,
                password: payload.password,
            });
            return { error: null, user: response.data };
        } catch (error) {
            return rejectWithValue({ error: error.response.message });
        }
    },
);

export const onChangeFieldValues = createAsyncThunk(
    'identity/change-form-value',
    async () => {},
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(checkAuth.pending, state => {
            // both `state` and `action` are now correctly typed
            // based on the slice state and the `pending` action creator
            return {
                ...state,
                status: StatusType.Pending,
                action: ActionType.Authenticate,
            };
        });
        builder.addCase(checkAuth.fulfilled, (state, action) => {
            return {
                ...state,
                status: StatusType.Success,
                user: action.payload.user,
                isAuth: action.payload.isAuth,
                action: ActionType.Authenticate,
            };
        });
        builder.addCase(
            checkAuth.rejected,
            (state, action: PayloadAction<any>) => {
                return {
                    ...state,
                    status: StatusType.Error,
                    user: action.payload.user,
                    isAuth: action.payload.isAuth,
                    error: action.payload.error,
                    action: ActionType.Authenticate,
                };
            },
        );
        builder.addCase(login.pending, state => {
            return {
                ...state,
                status: StatusType.Pending,
                action: ActionType.Login,
            };
        });
        builder.addCase(login.fulfilled, (state, action) => {
            return {
                ...state,
                action: ActionType.Login,
                status: StatusType.Success,
                user: action.payload.user,
                isAuth: action.payload.isAuth,
                error: action.payload.error,
            };
        });
        builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
            return {
                ...state,
                status: StatusType.Error,
                action: ActionType.Login,
                error: action.payload.error,
                user: action.payload.user,
                isAuth: action.payload.isAuth,
            };
        });
        builder.addCase(register.pending, state => {
            return {
                ...state,
                status: StatusType.Pending,
                action: ActionType.Register,
            };
        });
        builder.addCase(register.fulfilled, (state, action) => {
            return {
                ...state,
                status: StatusType.Success,
                action: ActionType.Register,
                error: action.payload.error,
            };
        });
        builder.addCase(register.rejected, state => {
            return {
                ...state,
                status: StatusType.Error,
                action: ActionType.Register,
            };
        });
        builder.addCase(logout.pending, state => {
            return {
                ...state,
                status: StatusType.Pending,
                action: ActionType.Logout,
            };
        });
        builder.addCase(logout.fulfilled, state => {
            return {
                ...state,
                status: StatusType.Success,
                action: ActionType.Logout,
            };
        });
        builder.addCase(logout.rejected, state => {
            return {
                ...state,
                status: StatusType.Error,
                action: ActionType.Logout,
            };
        });
        builder.addCase(onChangeFieldValues.fulfilled, state => {
            return {
                ...state,
                status: StatusType.Idle,
                action: ActionType.ChangeFieldValues,
                error: null,
            };
        });
    },
});

export const authSelector = (state: RootState) => state.auth;
export const authReducer = authSlice.reducer;
