import styled, { css } from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 8px 7.6px 1.4px rgba(123, 124, 1, 0.08);
    background-color: #ffffff;
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 25px 23px 6px 36px;
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Rate = styled.div<{ dataStatus: 'up' | 'down' }>`
    padding: 5px 18px;
    border-radius: 6px;
    align-self: flex-start;
    font-size: 20px;
    ${({ dataStatus }) =>
        dataStatus === 'up' &&
        css`
            background-color: #e3f6eb;
            color: #044b22;
        `}
    ${({ dataStatus }) =>
        dataStatus === 'down' &&
        css`
            background-color: #f8e9eb;
            color: #e56767;
        `}
`;

export const Amount = styled.div`
    font-size: 31px;
    font-weight: 600;
    color: #111111;
`;

export const Name = styled.div`
    font-size: 20.5px;
    font-weight: normal;
    color: #6c757d;
`;

export const Chart = styled.div`
    width: 100%;
    height: 72px;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`;
