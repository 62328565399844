import {
    SearchForm,
    FreeWordFeild,
    MoneyFeild,
    ExchangeFeild,
    StatusFeild,
    StyledSpace,
    StyledButton,
    DatePickerFeild,
} from './style';
import { Select } from 'antd';
import { useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const currentcies: CurrentcyType[] = [
    { id: '1', name: 'VND' },
    { id: '2', name: 'VNDC' },
];

const transactions: TransactionType[] = [
    { id: '1', name: 'Đầu tư' },
    { id: '2', name: 'Nâng cấp' },
];

const statuses: StatusType[] = [
    { id: '1', key: 'success', name: 'Thành công' },
    { id: '2', key: 'pending', name: 'Chờ duyệt' },
    { id: '2', key: 'canceled', name: 'Thất bại' },
];

const AdvancedSearch = () => {
    const history = useHistory();

    const parsed = queryString.parse(history.location.search);
    const [keyWord, setKeyWord] = useState(parsed.keyWord);
    const [currency, setCurrentcy] = useState(parsed.currency);
    const [transaction, setTransaction] = useState(parsed.transaction);
    const [status, setStatus] = useState(parsed.status);
    const [from, setFrom] = useState(moment(parsed.from).format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment(parsed.to).format('YYYY-MM-DD'));

    const onDatePickerChange = (_: any, [from, to]: any) => {
        setFrom(from);
        setTo(to);
    };

    const onFreeWordChange = (e: any) => {
        setKeyWord(e.target.value);
    };

    const onSearch = () => {
        const stringified = queryString.stringify(
            {
                keyWord,
                currency,
                transaction,
                status,
                from,
                to,
            },
            { sort: false },
        );

        history.push(`${history.location.pathname}?${stringified}`);
    };

    return (
        <SearchForm title='Tìm kiếm loại giao dịch'>
            <StyledSpace>
                <FreeWordFeild
                    placeholder='Tìm kiếm loại giao dịch'
                    value={keyWord || ''}
                    onChange={onFreeWordChange}
                />
                <MoneyFeild
                    placeholder='Loại tiền'
                    value={currency}
                    onChange={e => setCurrentcy(e)}
                >
                    {currentcies.map(c => (
                        <Select.Option key={c.id} value={c.name}>
                            {c.name}
                        </Select.Option>
                    ))}
                </MoneyFeild>

                <ExchangeFeild
                    placeholder='Loại giao dịch'
                    value={transaction}
                    onChange={e => setTransaction(e)}
                >
                    {transactions.map(c => (
                        <Select.Option key={c.id} value={c.name}>
                            {c.name}
                        </Select.Option>
                    ))}
                </ExchangeFeild>
            </StyledSpace>
            <StyledSpace>
                <StatusFeild
                    placeholder='Trạng thái'
                    value={status}
                    onChange={e => setStatus(e)}
                >
                    {statuses.map(c => (
                        <Select.Option key={c.key} value={c.name}>
                            {c.name}
                        </Select.Option>
                    ))}
                </StatusFeild>
                <DatePickerFeild
                    placeholder={['Từ ngày', 'Tới ngày']}
                    format='YYYY-MM-DD'
                    // value={[from, to]}
                    onChange={onDatePickerChange}
                ></DatePickerFeild>
                <StyledButton onClick={onSearch}>Tìm kiếm</StyledButton>
            </StyledSpace>
        </SearchForm>
    );
};

export default AdvancedSearch;
