import { Button } from 'antd';
import styled from 'styled-components';
import { color, radius } from 'themes';

const StyledButton = styled((props: any) => (
    <Button htmlType='submit' {...props} />
))`
    && {
        background: #ffcb00;
        width: 100%;
        padding: 12px;
        height: auto;
        border-radius: ${radius.border};
        outline: none;
        box-shadow: none;
        border: none;
        font-size: 16px;
        &:hover {
            color: ${color.black};
        }
    }
`;

const Primary = (props: any) => <StyledButton {...props} />;
export default Primary;
