import styled from 'styled-components';

const Layout = styled.section<{ url: string }>`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${props => props.url});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

// Mark the function as a generic using P (or whatever variable you want)
export default function withBackground<P>(
    // Then we need to type the incoming component.
    // This creates a union type of whatever the component
    WrappedComponent: React.ComponentType<P>,
    // The background's URL
    url: string,
) {
    const ComponentWithBackground = (props: P) => {
        // At this point, the props being passed in are the original props the component expects.
        return (
            <Layout url={url}>
                <WrappedComponent {...props} />
            </Layout>
        );
    };
    return ComponentWithBackground;
}
