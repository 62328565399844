import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox='0 0 18 18'
            xmlns='http://www.w3.org/2000/svg'
            width='1em'
            height='1em'
            {...props}
        >
            <path
                d='M17.176.293a1 1 0 010 1.414l-7.028 7.027 7.028 7.027a.999.999 0 11-1.414 1.414l-7.028-7.028-7.027 7.028a.997.997 0 01-1.414 0 .999.999 0 010-1.414L7.32 8.734.293 1.707A1 1 0 011.707.293L8.734 7.32 15.762.293a1 1 0 011.414 0z'
                fill='#F2EEE8'
            />
        </svg>
    );
}

export default SvgComponent;
