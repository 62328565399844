import { useAuthState } from 'hooks';
import { HOME } from 'paths';
import React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
    RouteComponentProps,
} from 'react-router-dom';

// Override RouteProps -> set "component" is required
interface IPublicRouteProps extends RouteProps {
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
    restricted?: boolean;
}

const PublicRoute = ({
    component: Component,
    restricted = false,
    ...rest
}: IPublicRouteProps) => {
    const { isAuthenticated } = useAuthState();
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated && restricted ? (
                    <Redirect to={HOME} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default PublicRoute;
