import { useEffect, useContext } from 'react';
import { GlobalContext } from 'contexts';
import Default from './Default';
import Dashboard from './Dashboard';

const themeConfigDefault = {
    headerDark: false,
    bodyDark: false,
    footerDark: false,
};

const LayoutWrapper = ({
    children,
    darkTheme = false,
    themeConfig = null,
}: any) => {
    const gContext = useContext(GlobalContext);

    useEffect(() => {
        if (darkTheme) {
            gContext.changeTheme({
                headerDark: true,
                bodyDark: true,
                footerDark: true,
            });
        } else {
            gContext.changeTheme({ ...themeConfigDefault, ...themeConfig });
        }
    }, []);

    return <>{children}</>;
};

export default LayoutWrapper;

export const DefaultLayout = (props: IDefaultLayoutProps) => {
    return (
        <LayoutWrapper>
            <Default {...props} />
        </LayoutWrapper>
    );
};

export const DashboardLayout = (props: any) => {
    return (
        <LayoutWrapper>
            <Dashboard {...props} />
        </LayoutWrapper>
    );
};
