import { Input } from 'antd';
import styled from 'styled-components';

const inputStyles = {
    background: '#eee',
    padding: '8px 12px',
    borderRadius: 12,
    border: 'none',
};

export const StyledPasswordInput = styled((props: any) => (
    <Input.Password {...props} />
))`
    && {
        ${inputStyles}
        .ant-input {
            background: #eee;
        }
        &:hover {
            background: #eee;
        }
    }
`;

const Password = (props: any) => <StyledPasswordInput {...props} />;
export default Password;
