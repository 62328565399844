import Cookies from 'universal-cookie';

const { REACT_APP_NAME } = process.env;

export enum ECookieVariable {
    USER_ACCESS_TOKEN = 'usr_tk',
    USER_ACCESS_TOKEN_REFRESH = 'usr_tk_rf',
    USER_LOCALE = 'locale',
}

export default class CookieService {
    cookies: Cookies;

    constructor() {
        this.cookies = new Cookies();
    }

    get(name: ECookieVariable): string {
        return this.cookies.get(this._convertCookieName(name));
    }

    set(name: ECookieVariable, value: string, options = {}): void {
        const ONE_DAY = 86400;
        const cookieOptions = {
            maxAge: ONE_DAY * 120,
            path: '/',
            ...options,
        };
        this.cookies.set(this._convertCookieName(name), value, cookieOptions);
    }

    remove(name: ECookieVariable): void {
        this.cookies.remove(this._convertCookieName(name));
    }

    /**
     * Convert the cookie name
     * @param name The cookie name to be converted
     * @return {String} {REACT_APP_NAME}_name
     */
    _convertCookieName(name: ECookieVariable): string {
        return `${REACT_APP_NAME?.toLowerCase()}_${name}`;
    }
}
