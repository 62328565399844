import {
    SearchForm,
    FreeWordFeild,
    TypeField,
    ExchangeFeild,
    StatusFeild,
    StyledSpace,
    StyledButton,
    DatePickerFeild,
} from './style';
import { Select } from 'antd';
import { useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

type UserType = {
    id: string;
    name: string;
};

type StatusType = {
    id: string;
    key: string;
    name: string;
};

const userTypes: UserType[] = [
    { id: '1', name: 'Đồng hành' },
    { id: '2', name: 'Chủ đầu tư' },
];

const transactions: TransactionType[] = [
    { id: '1', name: 'Đầu tư' },
    { id: '2', name: 'Nâng cấp' },
];

const statuses: StatusType[] = [
    { id: '1', key: 'active', name: 'Hoạt động' },
    { id: '2', key: 'inactive', name: 'Khoá' },
];

const UserSearch = () => {
    const history = useHistory();

    const parsed = queryString.parse(history.location.search);
    const [keyWord, setKeyWord] = useState(parsed.keyWord);
    const [userType, setUserType] = useState(parsed.userType);
    const [transaction, setTransaction] = useState(parsed.transaction);
    const [status, setStatus] = useState(parsed.status);
    const [from, setFrom] = useState(moment(parsed.from).format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment(parsed.to).format('YYYY-MM-DD'));

    const onDatePickerChange = (_: any, [from, to]: any) => {
        setFrom(from);
        setTo(to);
    };

    const onFreeWordChange = (e: any) => {
        setKeyWord(e.target.value);
    };

    const onSearch = () => {
        const stringified = queryString.stringify(
            {
                keyWord,
                userType,
                transaction,
                status,
                from,
                to,
            },
            { sort: false },
        );

        history.push(`${history.location.pathname}?${stringified}`);
    };

    return (
        <SearchForm title='Tìm kiếm thành viên'>
            <StyledSpace>
                <FreeWordFeild
                    placeholder='Tìm kiếm thành viên'
                    value={keyWord || ''}
                    onChange={onFreeWordChange}
                />
                <TypeField
                    placeholder='Gói thành viên'
                    value={userType}
                    onChange={e => setUserType(e)}
                >
                    {userTypes.map(c => (
                        <Select.Option key={c.id} value={c.name}>
                            {c.name}
                        </Select.Option>
                    ))}
                </TypeField>

                <ExchangeFeild
                    placeholder='Loại giao dịch'
                    value={transaction}
                    onChange={e => setTransaction(e)}
                >
                    {transactions.map(c => (
                        <Select.Option key={c.id} value={c.name}>
                            {c.name}
                        </Select.Option>
                    ))}
                </ExchangeFeild>
            </StyledSpace>
            <StyledSpace>
                <StatusFeild
                    placeholder='Trạng thái'
                    value={status}
                    onChange={e => setStatus(e)}
                >
                    {statuses.map(c => (
                        <Select.Option key={c.key} value={c.name}>
                            {c.name}
                        </Select.Option>
                    ))}
                </StatusFeild>
                <DatePickerFeild
                    placeholder={['Từ ngày', 'Tới ngày']}
                    format='YYYY-MM-DD'
                    // value={[from, to]}
                    onChange={onDatePickerChange}
                ></DatePickerFeild>
                <StyledButton onClick={onSearch}>Tìm kiếm</StyledButton>
            </StyledSpace>
        </SearchForm>
    );
};

export default UserSearch;
