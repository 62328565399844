import { DashboardLayout } from 'layouts';
import styled, { CSSProperties } from 'styled-components';
import { List, Form, Table } from 'components';
import { Tag, Typography } from 'antd';
import { getCommaNumber } from 'utils/numeral';
import mkaIcon from '../../assets/images/icon-mka.png';
import vndcIcon from '../../assets/images/icon-vndc.png';
import kyQuyIcon from '../../assets/images/icon-ky-quy.png';
import { Space, Progress } from 'antd';
const { Text } = Typography;

const AssetCardsListWrapper = styled.div``;

const TagStyles: CSSProperties = {
    fontSize: 20,
    height: 'auto',
    padding: '8px 14px',
    borderRadius: 6,
    minWidth: 134,
    textAlign: 'center',
};

const assetCards: IListAssetCardsProps = {
    cards: [
        {
            name: 'VND',
            amount: 50000000,
            icon: mkaIcon,
        },
        {
            name: 'VNDC',
            amount: 60878778,
            icon: vndcIcon,
        },
        {
            name: 'Ký quỹ',
            amount: 5000000,
            icon: kyQuyIcon,
        },
    ],
};

interface IProjectProps {
    name: string;
    hash: string;
    startDate: string;
    endDate: string;
    investors: number;
    pieces: number;
    targetAmount: number;
    currentAmount: number;
    currency: 'VND' | 'VNDC';
    status: 'success' | 'pending' | 'canceled';
}

const transactions: IProjectProps[] = [
    {
        name: 'Nhà số B26',
        hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        startDate: '12/05/2021',
        endDate: '30/05/2021',
        investors: 35,
        pieces: 100,
        currency: 'VND',
        targetAmount: 5000000000,
        currentAmount: 3500000000,
        status: 'success',
    },
    {
        name: 'Nhà số B26',
        hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        startDate: '12/05/2021',
        endDate: '30/05/2021',
        investors: 35,
        pieces: 100,
        currency: 'VND',
        targetAmount: 5000000000,
        currentAmount: 3500000000,
        status: 'success',
    },
    {
        name: 'Nhà số B26',
        hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        startDate: '12/05/2021',
        endDate: '30/05/2021',
        investors: 35,
        pieces: 100,
        currency: 'VND',
        targetAmount: 5000000000,
        currentAmount: 3500000000,
        status: 'success',
    },
    {
        name: 'Nhà số B26',
        hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        startDate: '12/05/2021',
        endDate: '30/05/2021',
        investors: 35,
        pieces: 100,
        currency: 'VND',
        targetAmount: 5000000000,
        currentAmount: 3500000000,
        status: 'pending',
    },
    {
        name: 'Nhà số B26',
        hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        startDate: '12/05/2021',
        endDate: '30/05/2021',
        investors: 35,
        pieces: 100,
        currency: 'VND',
        targetAmount: 5000000000,
        currentAmount: 3500000000,
        status: 'success',
    },
    {
        name: 'Nhà số B26',
        hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        startDate: '12/05/2021',
        endDate: '30/05/2021',
        investors: 35,
        pieces: 100,
        currency: 'VND',
        targetAmount: 5000000000,
        currentAmount: 3500000000,
        status: 'success',
    },
];

const SuccessTag = () => (
    <Tag
        color='#e3f6eb'
        style={{
            ...TagStyles,
            color: '#044b22',
        }}
    >
        Thành công
    </Tag>
);

const PendingTag = () => (
    <Tag
        color='#fff1c7'
        style={{
            ...TagStyles,
            color: '#9f7400',
        }}
    >
        Đang gọi vốn
    </Tag>
);

const CanceledTag = () => (
    <Tag
        color='#ffcccc'
        style={{
            ...TagStyles,
            color: '#8c1212',
        }}
    >
        Đã hủy
    </Tag>
);

const HashString = (hash: string) => {
    const renderString = `${hash.slice(0, 8)}...${hash.slice(
        hash.length - 3,
        hash.length,
    )}`;
    return <>{SubCell(renderString, true)}</>;
};

const SubCell = (text: string, highLight = false) => {
    const style = {
        fontSize: 10.5,
        color: highLight ? '#084ec3' : '#6c757d',
    };
    return <Text style={style}>{text}</Text>;
};

const columns = [
    {
        title: 'Tên dự án',
        dataIndex: 'name',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IProjectProps) => (
            <>
                {text}
                <br />
                {HashString(record.hash)}
            </>
        ),
    },
    {
        title: 'Thời gian',
        dataIndex: 'startDate',
        // eslint-disable-next-line react/display-name
        render: (startDate: string, record: IProjectProps) => (
            <>
                <span>{startDate}</span>
                <br />
                <span>{record.endDate}</span>
            </>
        ),
    },
    {
        title: 'Tiến độ',
        // eslint-disable-next-line react/display-name
        render: (_: any, record: IProjectProps) => (
            <>
                <Progress
                    percent={(record.currentAmount / record.targetAmount) * 100}
                    showInfo={false}
                />
            </>
        ),
    },
    {
        title: 'Nhà đầu tư',
        dataIndex: 'investor',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IProjectProps) => (
            <>
                {text}
                <br />
                {record.pieces}
            </>
        ),
    },
    {
        title: 'Tổng tiền huy động',
        dataIndex: 'targetAmount',
        // eslint-disable-next-line react/display-name
        render: (amount: number, record: IProjectProps) => (
            <Text style={{ color: '#e76200' }}>
                {getCommaNumber(amount)} {record.currency}
            </Text>
        ),
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        // eslint-disable-next-line react/display-name
        render: (status: 'success' | 'pending' | 'canceled') => {
            switch (status) {
                case 'success':
                    return <SuccessTag />;
                case 'pending':
                    return <PendingTag />;
                case 'canceled':
                    return <CanceledTag />;
            }
        },
    },
];

const TransactionHistoryWrapper = styled.div`
    && {
        table {
            padding-left: 20px;
            padding-top: 20px;
        }
        .ant-table-cell {
            line-height: 1;
            font-size: 18px !important;
        }
    }
`;

const Projects = () => {
    return (
        <DashboardLayout>
            <Space size='middle' direction='vertical' style={{ width: '100%' }}>
                <AssetCardsListWrapper>
                    <List.AssetCards {...assetCards} />
                </AssetCardsListWrapper>
                <Form.AdvancedSearch />
                <TransactionHistoryWrapper>
                    <Table.Transaction
                        dataSource={transactions}
                        columns={columns}
                    />
                </TransactionHistoryWrapper>
            </Space>
        </DashboardLayout>
    );
};

export default Projects;
