const menu: INavbarProps = {
    email: 'hi@moonka.io',
    phone: '1800 868686',
    items: [
        {
            to: '/',
            label: 'Trang chủ',
        },
        {
            to: '/',
            label: 'Sản phẩm',
        },
        {
            to: '/',
            label: 'Sàn P2P',
        },
        {
            to: '/',
            label: 'Đội ngũ',
        },
        {
            to: '/',
            label: 'Liên hệ',
        },
    ],
};

export default menu;
