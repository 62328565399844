import { Card } from 'components';
import { List, ListItem } from './style';

const AssetCards = ({ cards, direction }: IListStatisticsCardsProps) => {
    return (
        <List dataDirection={direction}>
            {cards.map(props => (
                <ListItem key={props.name}>
                    <Card.StatisticsCard {...props} />
                </ListItem>
            ))}
        </List>
    );
};

export default AssetCards;
