import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            id='prefix__Layer_1'
            xmlns='http://www.w3.org/2000/svg'
            x={0}
            y={0}
            viewBox='0 0 482.7 108'
            xmlSpace='preserve'
            {...props}
        >
            <style>
                {
                    '.prefix__st0{fill:#ffcb00}.prefix__st1{enable-background:new}'
                }
            </style>
            <g id='prefix__Layer_1-2'>
                <path
                    className='prefix__st0'
                    d='M87.4 26.9h.9c.6.1 1.1-.2 1.3-.7.1-.6-.2-1.1-.7-1.3L45.6.1c-.3-.2-.7-.2-1.1 0L.5 26.5c-.3.2-.5.6-.5.9v48.4c0 .4.2.7.6.9l43.1 22.8c.5.3 1.1.1 1.4-.4.2-.3.2-.7 0-1C32.4 74.8 41 45.5 64.4 32.8c7.1-3.9 15-5.9 23-5.9z'
                />
                <path
                    className='prefix__st0'
                    d='M73.3 90c.4.2.9.1 1.2-.3.1-.1.1-.3.1-.4V66.7c0-.3.2-.6.4-.8l17.8-9.8c.4-.2.6-.7.4-1.2-.1-.2-.2-.3-.4-.4l-12.6-6.7c-.3-.1-.6-.1-.8 0L59.2 59.1c-.3.2-.4.4-.4.8v21.8L73.3 90z'
                />
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M119.4 30.7h10.4l14.5 29.7 14.5-29.7h10.4v49.4h-10.4V48l-11.5 23.6h-6L129.8 48v32.1h-10.4V30.7z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M182.2 55.7c0-8.9 2-15.4 5.9-19.5 4-4.1 10-6.2 18.1-6.2 8.1 0 14.1 2.1 18.1 6.2 4 4.1 5.9 10.6 5.9 19.5 0 16.8-8 25.2-24 25.2s-24-8.4-24-25.2zm34.2 12.4c2.2-2.7 3.3-6.8 3.3-12.4 0-6.1-1.1-10.4-3.2-13-2.1-2.6-5.6-3.9-10.3-3.9-4.7 0-8.1 1.3-10.3 3.9-2.1 2.6-3.2 6.9-3.2 13 0 5.6 1.1 9.7 3.3 12.4 2.2 2.7 5.6 4 10.2 4s8-1.4 10.2-4z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M242.4 55.7c0-8.9 2-15.4 5.9-19.5 4-4.1 10-6.2 18.1-6.2 8.1 0 14.1 2.1 18.1 6.2 4 4.1 5.9 10.6 5.9 19.5 0 16.8-8 25.2-24 25.2s-24-8.4-24-25.2zm34.2 12.4c2.2-2.7 3.3-6.8 3.3-12.4 0-6.1-1.1-10.4-3.2-13-2.1-2.6-5.6-3.9-10.3-3.9-4.7 0-8.1 1.3-10.3 3.9-2.1 2.6-3.2 6.9-3.2 13 0 5.6 1.1 9.7 3.3 12.4 2.2 2.7 5.6 4 10.2 4s8-1.4 10.2-4z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M306.1 30.7h9l23.4 32.9V30.7h10.4v49.4h-9l-23.4-32.9v32.9h-10.4V30.7z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M367.8 30.7h10.4v20.9l19-20.9H409l-17.9 20L410 80.1h-11l-14.2-22.8-6.6 7.1v15.7h-10.4V30.7z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M441.3 30.7h11.4l18.8 49.4h-10.7L456 68.2h-18.2l-4.6 11.9h-10.8l18.9-49.4zm13.2 29.6l-7.6-19.9-7.5 19.9h15.1z'
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
