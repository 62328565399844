import styled, { css } from 'styled-components';

export const List = styled.div<{ dataDirection: 'vertical' | 'horizontal' }>`
    display: flex;
    margin: -10px;
    ${({ dataDirection }) =>
        dataDirection === 'vertical' &&
        css`
            flex-direction: column;
            ${ListItem} {
                width: 100%;
            }
        `}
    ${({ dataDirection }) =>
        dataDirection === 'horizontal' &&
        css`
            ${ListItem} {
                width: 33.33%;
            }
        `}
`;

export const ListItem = styled.div`
    padding: 10px;
`;
