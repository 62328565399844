import styled from 'styled-components';
import { Layout as AntLayout, Space } from 'antd';

export const Layout = styled(AntLayout)`
    background: transparent;
`;

export const Container = styled((props: any) => <Space {...props} />)`
    width: 100%;
    background: transparent;

    && ${Layout} {
        max-width: 1300px;
        margin: 0 auto;
    }
`;
