import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            id='prefix__Layer_1'
            xmlns='http://www.w3.org/2000/svg'
            x={0}
            y={0}
            viewBox='0 0 367.6 343'
            xmlSpace='preserve'
            {...props}
        >
            <style>
                {
                    '.prefix__st0{fill:#ffcb00}.prefix__st1{enable-background:new}'
                }
            </style>
            <g id='prefix__Layer_1-2'>
                <path
                    className='prefix__st0'
                    d='M256.8 56.6h1.8c1.2 0 2.2-1 2.2-2.2 0-.8-.4-1.5-1.1-1.9L168.7.3c-.7-.4-1.5-.4-2.2 0L73.8 55.8c-.7.4-1.1 1.1-1.1 1.9v101.9c0 .8.4 1.6 1.2 2l90.8 48.1c1.1.6 2.4.2 3-.9.4-.7.4-1.5 0-2.1-26.8-49.2-8.6-110.9 40.6-137.6 14.9-8.1 31.6-12.4 48.5-12.5z'
                />
                <path
                    className='prefix__st0'
                    d='M227.1 189.5c.9.5 2 .2 2.5-.7.2-.3.2-.6.2-.9v-47.5c0-.7.4-1.3.9-1.6l37.5-20.7c.9-.5 1.2-1.6.7-2.5-.2-.3-.4-.6-.7-.7l-26.5-14c-.5-.3-1.2-.3-1.7 0l-42.6 23.5c-.6.3-.9.9-.9 1.6v45.9l30.6 17.6z'
                />
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M4.4 265.8h10.4l14.5 29.7 14.5-29.7h10.4v49.4H43.8V283l-11.5 23.6h-6L14.7 283v32.1H4.4v-49.3z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M67.1 290.7c0-8.9 2-15.4 5.9-19.5 4-4.1 10-6.2 18.1-6.2s14.1 2.1 18.1 6.2 5.9 10.6 5.9 19.5c0 16.8-8 25.2-24 25.2s-24-8.4-24-25.2zm34.2 12.4c2.2-2.7 3.3-6.8 3.3-12.4 0-6.1-1.1-10.4-3.2-13s-5.6-3.9-10.3-3.9c-4.7 0-8.1 1.3-10.3 3.9-2.1 2.6-3.2 6.9-3.2 13 0 5.6 1.1 9.7 3.3 12.4 2.2 2.7 5.6 4 10.2 4s8-1.3 10.2-4z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M127.4 290.7c0-8.9 2-15.4 5.9-19.5 4-4.1 10-6.2 18.1-6.2s14.1 2.1 18.1 6.2 5.9 10.6 5.9 19.5c0 16.8-8 25.2-24 25.2s-24-8.4-24-25.2zm34.2 12.4c2.2-2.7 3.3-6.8 3.3-12.4 0-6.1-1.1-10.4-3.2-13-2.1-2.6-5.6-3.9-10.3-3.9s-8.1 1.3-10.3 3.9c-2.1 2.6-3.2 6.9-3.2 13 0 5.6 1.1 9.7 3.3 12.4 2.2 2.7 5.6 4 10.2 4s8-1.3 10.2-4z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M191 265.8h9l23.4 32.9v-32.9h10.4v49.4h-9l-23.4-32.9v32.9H191v-49.4z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M252.7 265.8h10.4v20.9l19-20.9H294l-17.9 20 18.8 29.4h-11l-14.2-22.8-6.6 7.1v15.7h-10.4v-49.4z'
                    />
                </g>
                <g className='prefix__st1'>
                    <path
                        className='prefix__st0'
                        d='M326.2 265.8h11.4l18.8 49.4h-10.7l-4.7-11.9h-18.2l-4.6 11.9h-10.8l18.8-49.4zm13.2 29.5l-7.6-19.9-7.5 19.9h15.1z'
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
