import { Form, Typography } from 'antd';
import styled from 'styled-components';
import { color, spacing } from 'themes';
const { Title } = Typography;

export const Container = styled.section`
    display: flex;
    align-items: center;
`;

export const LogoWrapper = styled.div`
    max-width: 154px;
    width: 100%;
    margin-right: 100px;
    svg {
        width: 100%;
    }
`;

export const FormWrapper = styled.div`
    background: ${color.white};
    padding: ${spacing.desktop.bodyPadding};
    border-radius: 24px;
    min-width: 384px;
    margin: 50px 0;
`;

export const Headline = styled((props: any) => <Title {...props} />)`
    && {
        margin-bottom: 24px;
    }
`;

export const StyledFormItem = styled((props: any) => <Form.Item {...props} />)`
    && {
        .ant-col.ant-form-item-label > label {
            color: #828282;
        }
        margin-bottom: 16px;
    }
    .ant-form-item-explain {
      margin-top: 8px;
    }
    margin-bottom: 16px;
  }
`;
