import Desktop from './Desktop';
import Mobile from './Mobile';
import { ResponsiveLayout } from 'core';

const Register = (props: any) => {
    return (
        <ResponsiveLayout
            desktopView={<Desktop {...props} />}
            mobileView={<Mobile {...props} />}
        />
    );
};

export default Register;
