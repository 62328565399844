import { CookieService, ECookieVariable } from 'services';

export enum ELocale {
    ENGLISH = 'en_US',
    VIETNAM = 'vi_VN',
}

export type TLocale = {
    id: number;
    key: ELocale;
    label: string;
    isActive: boolean;
    order: number;
};

export const locales: TLocale[] = [
    {
        id: 1,
        key: ELocale.ENGLISH,
        label: 'English',
        isActive: true,
        order: 1,
    },
    {
        id: 2,
        key: ELocale.VIETNAM,
        label: 'Việt Nam',
        isActive: true,
        order: 2,
    },
].sort((a, b) => a.order - b.order);

export const setLocale = (locale: ELocale): void => {
    new CookieService().set(ECookieVariable.USER_LOCALE, locale);
    if (typeof window !== 'undefined') window.location.reload();
};

export const getLocale = (): TLocale => {
    let locale: TLocale;

    const fromCookie = new CookieService().get(ECookieVariable.USER_LOCALE);
    const currentLocale = locales.find(item => item.key === fromCookie);
    if (!currentLocale) {
        locale = locales[0];
        new CookieService().set(ECookieVariable.USER_LOCALE, locale.key);
    } else {
        locale = currentLocale;
    }

    return locale;
};

export const getLocaleKey = (isUseJSFunc = false): string =>
    isUseJSFunc ? getLocale().key.replace('_', '-') : getLocale().key;
