import { Input } from 'antd';
import styled from 'styled-components';

const inputStyles = {
    background: '#eee',
    padding: '8px 12px',
    borderRadius: 12,
    border: 'none',
};

export const StyledInput = styled((props: any) => <Input {...props} />)`
    && {
        ${inputStyles}
    }
`;

const Text = (props: any) => <StyledInput {...props} />;
export default Text;
