import { DashboardLayout } from 'layouts';
import { CSSProperties } from 'styled-components';
import { Form, Table } from 'components';
import { Tag, Typography } from 'antd';
import { getCommaNumber } from 'utils/numeral';
import { Space } from 'antd';
const { Text } = Typography;

const TagStyles: CSSProperties = {
    fontSize: 20,
    height: 'auto',
    padding: '8px 14px',
    borderRadius: 6,
    minWidth: 134,
    textAlign: 'center',
};

interface IUserProps {
    txn_hash: string;
    time: string;
    name: string;
    email: string;
    type: 'Đồng hành' | 'Nhà đầu tư';
    amount: number;
    status: 'active' | 'inactive';
}

const transactions: IUserProps[] = [
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        name: 'Tri Nguyen',
        type: 'Nhà đầu tư',
        email: 'kevin@gmail.com',
        amount: 5000000,
        status: 'active',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        name: 'Tri Nguyen',
        type: 'Đồng hành',
        email: 'kevin@gmail.com',
        amount: 5000000,
        status: 'inactive',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        name: 'Tri Nguyen',
        type: 'Đồng hành',
        email: 'kevin@gmail.com',
        amount: 5000000,
        status: 'active',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        name: 'Tri Nguyen',
        type: 'Nhà đầu tư',
        email: 'kevin@gmail.com',
        amount: 5000000,
        status: 'active',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        name: 'Tri Nguyen',
        type: 'Đồng hành',
        email: 'kevin@gmail.com',
        amount: 5000000,
        status: 'inactive',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        name: 'Tri Nguyen',
        type: 'Nhà đầu tư',
        email: 'kevin@gmail.com',
        amount: 5000000,
        status: 'active',
    },
];

const ActiveTag = () => (
    <Tag
        color='#e3f6eb'
        style={{
            ...TagStyles,
            color: '#044b22',
        }}
    >
        Hoạt động
    </Tag>
);

const InactiveTag = () => (
    <Tag
        color='#fff1c7'
        style={{
            ...TagStyles,
            color: '#9f7400',
        }}
    >
        Hết hạn
    </Tag>
);

const HashString = (hash: string) => {
    const renderString = `${hash.slice(0, 8)}...${hash.slice(
        hash.length - 3,
        hash.length,
    )}`;
    return <>{renderString}</>;
};

const SubCell = (text: string, highLight = false) => {
    const style = {
        fontSize: 10.5,
        color: highLight ? '#084ec3' : '#6c757d',
    };
    return <Text style={style}>{text}</Text>;
};

const TypeCell = (text: 'invest' | 'upgrade') => {
    const style = {
        color: '#084ec3',
    };
    return (
        <Text style={style}>{text === 'invest' ? 'Đầu tư' : 'Nâng cấp'}</Text>
    );
};

const columns = [
    {
        title: 'Txn Hash',
        dataIndex: 'txn_hash',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IUserProps) => (
            <>
                {HashString(text)}
                <br />
                {SubCell(record.time, true)}
            </>
        ),
    },
    {
        title: 'Gói thành viên',
        dataIndex: 'type',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IUserProps) => (
            <>
                {text}
                <br />
                {SubCell(record.email)}
            </>
        ),
    },
    {
        title: 'Loại giao dịch',
        dataIndex: 'exchange_type',
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: TypeCell,
    },
    {
        title: 'Số tiền',
        dataIndex: 'amount',
        align: 'center',
        // width: 130,
        // eslint-disable-next-line react/display-name
        render: (amount: number) => (
            <Text style={{ color: '#e76200' }}>{getCommaNumber(amount)}</Text>
        ),
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: (status: 'active' | 'inactive') => {
            switch (status) {
                case 'active':
                    return <ActiveTag />;
                case 'inactive':
                    return <InactiveTag />;
            }
        },
    },
];

const Users = () => {
    return (
        <DashboardLayout>
            <Space size='middle' direction='vertical' style={{ width: '100%' }}>
                <Form.UserSearch />
                <Table.User dataSource={transactions} columns={columns} />
            </Space>
        </DashboardLayout>
    );
};

export default Users;
