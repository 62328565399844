import React from 'react';
import { breakpoint as breakpoints } from 'variables';
import { useWindowDimensions } from 'hooks';

interface IResponsiveLayout {
    breakpoint?: number;
    mobileView: React.ReactElement;
    desktopView: React.ReactElement;
}

const ResponsiveLayout = (props: IResponsiveLayout) => {
    const { breakpoint = breakpoints.lgMin, mobileView, desktopView } = props;
    const { width } = useWindowDimensions();
    if (typeof window === `undefined`) {
        return <div />;
    }

    return width > breakpoint ? desktopView : mobileView;
};

export default ResponsiveLayout;
