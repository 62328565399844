import styled from 'styled-components';
import { color } from 'themes';

export const Nav = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

export const NavMenu = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 32px;
`;

export const MainMenu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const MainMenuItem = styled.li`
    display: inline-block;
    font-size: 16px;
    color: ${color.white};
    padding: 0 20px;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
`;

export const SubMenu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #273449;
    margin-top: -10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
`;

export const SubMenuItem = styled.li`
    display: flex;
    align-items: center;
    padding: 0 10px;
    > a {
        font-size: 12px;
        color: ${color.white};
    }
`;

export const SubMenuIcon = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${color.white};
    margin-right: 6px;
    display: flex;
    padding: 2px;
    align-items: center;
    justify-content: center;
`;

export const Account = styled.div`
    color: ${color.white};
    display: flex;
    align-items: flex-end;
`;

export const AccountIcon = styled.div`
    color: ${color.white};
    font-size: 24px;
    margin-right: 6px;
`;

export const AccountAction = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

export const AccountActionItem = styled.div`
    font-size: 12px;
    line-height: 12px;
    padding: 0 6px;
    border-right: 1px solid ${color.white};
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: none;
    }
`;

export const AccountMenu = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccountDropdown = styled.div`
    font-size: 16px;
`;
