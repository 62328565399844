import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Card, Content, Text, Amount, Name, Rate, Chart } from './style';
const StatisticsCard = ({
    amount,
    name,
    chart,
    rate,
    status,
}: IStatisticsCardProps) => {
    const getAreaStyle = (): { stroke: string; fill: string } => {
        switch (status) {
            case 'down':
                return {
                    stroke: '#eabac1',
                    fill: '#f8e9eb',
                };
            case 'up':
                return {
                    stroke: '#aee5c7',
                    fill: '#e3f6eb',
                };
        }
    };

    return (
        <Card>
            <Content>
                <Text>
                    <Amount>{amount}</Amount>
                    <Name>{name}</Name>
                </Text>
                <Rate dataStatus={status}>{rate}</Rate>
            </Content>
            <Chart>
                <ResponsiveContainer>
                    <AreaChart
                        data={chart.data}
                        margin={{
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    >
                        <Tooltip />
                        <Area
                            name={name}
                            type='monotone'
                            dataKey={chart.dataKey}
                            {...getAreaStyle()}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Chart>
        </Card>
    );
};

export default StatisticsCard;
