import {
    Button,
    ButtonProps,
    Card,
    CardProps,
    Select,
    DatePicker,
    Input,
    SelectProps,
} from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { SearchProps } from 'antd/lib/input';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

export const SearchForm = styled((props: CardProps) => <Card {...props} />)`
    width: 100%;
    height: 224px;
    padding: 24px 28px 39px 36px;
    border-radius: 20px;
    box-shadow: 0px 8px 7.6px 1.4px rgba(123, 124, 1, 0.08);
    background-color: #ffffff;

    .ant-card-head,
    .ant-card-head-title {
        padding: 0;
        font-size: 20.5px;
        font-weight: 600;
        color: #111111;
    }

    .ant-card-head {
        border-bottom: none;
    }

    .ant-card-body {
        padding: 0;
    }
`;

export const StyledSpace = styled.div`
    margin-bottom: 16px;
`;

export const FreeWordFeild = styled((props: SearchProps) => (
    <Input.Search {...props} prefix='' />
))`
    && {
        width: calc((100% - 32px) * 0.35);
        margin-right: 16px;
        border: solid 1px #cdcdcd;
        border-radius: 20px;
        overflow: hidden;

        input {
            font-size: 20.5px;
            padding: 8px 15px;

            border: none;
        }
        .ant-input-group-addon {
            display: none;
        }
    }
`;

export const SelectFeild = styled((props: SelectProps<any>) => (
    <Select {...props} />
))`
    && {
        margin-right: 16px;

        .ant-select-selector {
            height: 50px;
            padding: 10px 15px;
            border-radius: 20px;
        }
        span {
            font-size: 20.5px;
        }
        span.ant-select-arrow {
            top: 50%;
            right: 20px;
        }
        span.anticon-down {
            font-size: 16.5px;
        }
        .ant-select-selection-search {
            padding: 8px 0;
        }
    }
`;

export const MoneyFeild = styled(SelectFeild)`
    && {
        width: calc((100% - 32px) * 0.3);
    }
`;

export const ExchangeFeild = styled(SelectFeild)`
    && {
        width: calc((100% - 32px) * 0.35);
        margin-right: 0;
    }
`;

export const StatusFeild = styled(SelectFeild)`
    && {
        width: calc((100% - 32px) * 0.2);
    }
`;

export const StyledButton = styled((props: ButtonProps) => (
    <Button {...props} />
))`
    && {
        width: calc((100% - 32px) * 0.2);
        height: 50px;
        padding: 0;
        border-radius: 20px;
        background-color: #084ec3;
        color: #ffffff;
        font-size: 20.5px;
        line-height: 50px;
    }
`;

export const DatePickerFeild = styled((props: RangePickerProps) => (
    <RangePicker {...props} />
))`
    && {
        width: calc((100% - 32px) * 0.6);
        margin-right: 16px;
        height: 50px;
        padding: 0 15px;
        font-size: 16.5px;
        border-radius: 20px;
        input {
            font-size: 20.5px;
        }
    }
`;
