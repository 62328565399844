import { Link, LinkProps } from 'react-router-dom';
import {
    Layout,
    Avatar,
    AvatarProps,
    Menu,
    Dropdown,
    DropDownProps,
    Button,
} from 'antd';
import styled from 'styled-components';
import { color } from 'themes';
import {
    UserOutlined,
    AppstoreFilled,
    BellFilled,
    DownOutlined,
    BarChartOutlined,
} from '@ant-design/icons';
import { BasicProps } from 'antd/lib/layout/layout';
import { SiderProps } from 'antd/lib/layout/Sider';
import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input/Search';
import { DASHBOARD, TRANSACTION } from 'paths';

const { Search } = Input;

const { Header, Sider, Content } = Layout;

const SIDER_WIDTH = 210;
const HEADER_HEIGHT = 136;

const StyledHeader = styled((props: BasicProps) => <Header {...props} />)`
    position: fixed;
    z-index: 1;
    width: calc(100% - ${SIDER_WIDTH}px);
    height: ${HEADER_HEIGHT}px;
    background: #ffcb00;
    color: ${color.black};
    padding: 8px 20px 8px 35px;
    display: flex;
    flex-direction: column;
`;

const HeaderInner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const StyledContent = styled((props: BasicProps) => <Content {...props} />)`
    margin-top: ${HEADER_HEIGHT}px;
    padding: 20px 35px;
`;

const StyledSider = styled((props: SiderProps) => <Sider {...props} />)`
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    background: #ffcb00;
`;

const SiderInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

const Logo = styled.div`
    width: 162px;
    height: 34px;
    background: #eee;
    margin: 20px auto;
`;

const SiderMenu = styled.div`
    flex: 1;
    width: 100%;
    background: #272e38;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 30px 18px;
`;

const StyledLink = styled((props: LinkProps) => <Link {...props} />)`
    color: ${color.white};
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #323b48;
    margin-top: 30px;
    &:first-child {
        margin-top: 0;
    }
`;

const Nav = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
`;

const SearchBar = styled.div`
    flex-basis: 620px;
    display: flex;
    align-items: center;
`;

const Head = styled.div`
    font-size: 26px;
    font-weight: 600;
`;

const StyledSearch = styled((props: SearchProps) => <Search {...props} />)`
    && {
        .ant-input-wrapper {
            .ant-input {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }
            .ant-input-group-addon {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                .ant-input-search-button {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    background: #e3e3e3;
                    border-color: #e3e3e3;
                    color: #000000;
                    box-shadow: none;
                }
            }
        }
    }
`;

const AccountBar = styled.div`
    display: flex;
`;

const Notification = styled.div`
    margin-right: 38px;
    display: flex;
    align-items: center;
`;

const NotifyButton = styled((props: any) => (
    <Button shape='circle' {...props} />
))`
    && {
        background: none;
        border: none;
        &:hover,
        &:focus {
            color: #000000;
        }
    }
`;

const User = styled.div`
    display: flex;
    align-items: center;
`;

const StyledAvatar = styled((props: AvatarProps) => <Avatar {...props} />)`
    margin-right: 10px;
`;

const StyledDropdown = styled((props: DropDownProps & any) => (
    <Dropdown {...props} />
))``;

const AntDropdownLink = styled((props: any) => (
    <a className='ant-dropdown-link' {...props} />
))`
    /* display: block; */
    color: #000000;
    font-size: 18px;
    &:hover {
        color: #000000;
    }
`;

const Dashboard = ({ children }: any) => {
    const onSearch = (value: string) => console.log(value);
    function handleMenuClick(e: any) {
        console.log('click', e);
    }
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key='1' icon={<UserOutlined />}>
                1st menu item
            </Menu.Item>
            <Menu.Item key='2' icon={<UserOutlined />}>
                2nd menu item
            </Menu.Item>
            <Menu.Item key='3' icon={<UserOutlined />}>
                3rd menu item
            </Menu.Item>
        </Menu>
    );
    return (
        <Layout>
            <StyledSider width={SIDER_WIDTH}>
                <SiderInner>
                    <Logo />
                    <SiderMenu>
                        <StyledLink to={DASHBOARD}>
                            <BarChartOutlined
                                style={{ marginRight: 12, fontSize: 20 }}
                            />{' '}
                            Thống kê
                        </StyledLink>
                        <StyledLink to={TRANSACTION}>
                            <AppstoreFilled
                                style={{ marginRight: 12, fontSize: 20 }}
                            />
                            Giao dịch
                        </StyledLink>
                    </SiderMenu>
                </SiderInner>
            </StyledSider>
            <Layout style={{ marginLeft: SIDER_WIDTH }}>
                <StyledHeader>
                    <HeaderInner>
                        <Nav>
                            <SearchBar>
                                <StyledSearch
                                    onSearch={onSearch}
                                    enterButton
                                    size='large'
                                />
                            </SearchBar>
                            <AccountBar>
                                <Notification>
                                    <NotifyButton
                                        icon={
                                            <BellFilled
                                                style={{ fontSize: 28 }}
                                            />
                                        }
                                    />
                                </Notification>
                                <User>
                                    <StyledDropdown
                                        overlay={menu}
                                        placement='bottomCenter'
                                        arrow
                                    >
                                        <AntDropdownLink
                                            onClick={(e: any) =>
                                                e.preventDefault()
                                            }
                                        >
                                            <StyledAvatar
                                                size={40}
                                                src='https://images.unsplash.com/photo-1531746020798-e6953c6e8e04'
                                            />{' '}
                                            andycarl <DownOutlined />
                                        </AntDropdownLink>
                                    </StyledDropdown>
                                </User>
                            </AccountBar>
                        </Nav>
                        <Head>Main Dashboard</Head>
                    </HeaderInner>
                </StyledHeader>
                <StyledContent>{children}</StyledContent>
            </Layout>
        </Layout>
    );
};

export default Dashboard;
