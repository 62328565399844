import styled from 'styled-components';

const StyledHeadline = styled.h3`
    font-size: 31px;
    font-weight: 600;
    color: #111111;
    padding-left: 14px;
    border-left: solid 7px#ffcb00;
    margin-bottom: 20px;
`;

const Headline = (props: any) => (
    <StyledHeadline>{props.children}</StyledHeadline>
);

export default Headline;
