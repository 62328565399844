import BarChart from './BarChart';
import Transaction from './Transaction';
import User from './User';

const Table = {
    BarChart,
    Transaction,
    User,
};

export default Table;
