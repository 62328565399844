import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            width='1em'
            height='1em'
            {...props}
        >
            <path
                d='M11.777 0c3.145 0 6.104 1.225 8.326 3.448 4.592 4.592 4.592 12.063.002 16.655a11.707 11.707 0 01-8.33 3.449 11.694 11.694 0 01-8.326-3.448A11.695 11.695 0 010 11.776C0 8.63 1.226 5.672 3.451 3.448A11.696 11.696 0 0111.777 0zm0 2a9.715 9.715 0 00-6.912 2.862A9.71 9.71 0 002 11.776a9.713 9.713 0 002.863 6.914 9.71 9.71 0 006.912 2.862 9.721 9.721 0 006.916-2.864c3.811-3.811 3.811-10.014-.002-13.826A9.715 9.715 0 0011.777 2zm-.132 13.232c.312 0 .574.106.789.32.213.213.318.476.318.788 0 .348-.111.62-.334.819a1.13 1.13 0 01-.773.297 1.17 1.17 0 01-.793-.293c-.227-.197-.34-.47-.34-.823 0-.313.11-.575.328-.788.219-.214.487-.32.805-.32zm.178-9.043c.718 0 1.355.133 1.904.399.55.264.977.627 1.277 1.082.3.457.45.954.45 1.489 0 .421-.084.791-.256 1.108a3.407 3.407 0 01-.61.822c-.236.231-.66.62-1.27 1.165a5.137 5.137 0 00-.405.407 1.61 1.61 0 00-.227.319 1.598 1.598 0 00-.115.29 9.788 9.788 0 00-.123.51c-.094.517-.39.775-.885.775a.908.908 0 01-.652-.253c-.176-.17-.264-.42-.264-.752 0-.418.064-.779.193-1.084.129-.304.3-.572.514-.802.213-.232.502-.505.863-.823.319-.278.547-.489.688-.63.142-.14.26-.297.357-.472.098-.174.147-.362.147-.566 0-.396-.15-.732-.444-1.005-.295-.271-.675-.408-1.142-.408-.545 0-.948.138-1.205.412-.258.276-.477.681-.655 1.217-.17.562-.49.841-.961.841a.946.946 0 01-.703-.293c-.191-.196-.287-.409-.287-.636 0-.472.153-.95.455-1.434.303-.484.744-.883 1.324-1.202.58-.317 1.258-.476 2.032-.476z'
                fill='#F2EEE8'
            />
        </svg>
    );
}

export default SvgComponent;
