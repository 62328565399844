import Close from './Close';
import Checked from './Checked';
import Danger from './Danger';
import Warning from './Warning';
import Logo from './Logo';
import LogoVertical from './LogoVertical';
import User from './User';
import Product from './Product';
import Lock from './Lock';
import Logout from './Logout';
import QRCode from './QRCode';
import Copy from './Copy';
import GooglePlay from './GooglePlay';
import AppStore from './AppStore';

const Icons = {
    Close,
    Checked,
    Danger,
    Warning,
    User,
    Product,
    Lock,
    Logout,
    QRCode,
    Copy,
    Logo,
    LogoVertical,
    GooglePlay,
    AppStore,
};

export default Icons;
