import styled from 'styled-components';
import { Radio, Input, Form } from 'antd';
import { color, spacing } from 'themes';

export const Container = styled.section`
    background: ${color.white};
    padding: ${spacing.desktop.bodyPadding};
    border-radius: 24px;
    min-width: 600px;
    margin: 120px auto 50px;
    align-self: flex-start;
`;

export const StyledRadioGroup = styled((props: any) => (
    <Radio.Group {...props} />
))`
    && {
        background: #fff4c9;
        border-radius: 30px;
        margin-bottom: 24px;
    }
`;

export const StyledRadioButton = styled((props: any) => (
    <Radio.Button {...props} />
))`
    && {
        &.ant-radio-button-wrapper {
            background: #fff4c9;
            border-radius: 30px;
            border: none;
            height: auto;
            padding: 8px 32px;
            font-size: 16px;
            box-shadow: none;
            &:hover {
                color: ${color.black};
            }
            &::before {
                content: none;
            }
            &.ant-radio-button-wrapper-checked {
                background: #ffcb00;
                color: ${color.black};
            }
        }
    }
`;

export const Content = styled.div``;

export const StyledInput = styled((props: any) => <Input {...props} />)`
    && {
        padding: 0;
        background: #f6f6f6;
        border-radius: 30px;
        .ant-input-prefix {
            background: #e7e7e7;
            margin: 0;
            padding: 12px 12px;
            padding-left: 14px;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }
        .ant-input {
            background: #f6f6f6;
            padding-left: 12px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }
`;

export const StyledFormItem = styled((props: any) => <Form.Item {...props} />)`
    && {
        .ant-form-item-explain {
            margin: 8px 0;
        }
    }
`;
