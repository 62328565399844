import { getCommaNumber } from 'utils/numeral';
import { Card, Content, Amount, MutedText, Icon } from './style';

const AssetCard = ({ amount, name, icon }: IAssetCardProps) => {
    return (
        <Card>
            <Content>
                <Amount>{getCommaNumber(amount)}</Amount>
                <MutedText>{name}</MutedText>
            </Content>
            <Icon src={icon} />
        </Card>
    );
};

export default AssetCard;
