import { useSelector } from 'react-redux';
import { authSelector, StatusType } from 'redux/features/authSlice';

function useAuthState() {
    const state = useSelector(authSelector);
    const isPending = state.status === StatusType.Pending;
    const isError = state.status === StatusType.Error;
    const isSuccess = state.status === StatusType.Success;
    const isAuthenticated = state.isAuth && state.user;
    return {
        ...state,
        isPending,
        isError,
        isSuccess,
        isAuthenticated,
    };
}

export default useAuthState;

// the UserProvider in UserContext.tsx is basically:
// const UserProvider = props => (
//   <UserContext.Provider value={useAuthState().user} {...props} />
// )
// and the useUser hook is basically this:
// const useUser = () => React.useContext(UserContext)
