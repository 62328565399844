import { DashboardLayout } from 'layouts';
import styled, { CSSProperties } from 'styled-components';
import { List, Form, Table } from 'components';
import { Tag, Typography } from 'antd';
import { getCommaNumber } from 'utils/numeral';
import mkaIcon from '../../assets/images/icon-mka.png';
import vndcIcon from '../../assets/images/icon-vndc.png';
import kyQuyIcon from '../../assets/images/icon-ky-quy.png';
import { Space } from 'antd';
const { Text } = Typography;

const AssetCardsListWrapper = styled.div``;

const TagStyles: CSSProperties = {
    fontSize: 20,
    height: 'auto',
    padding: '8px 14px',
    borderRadius: 6,
    minWidth: 134,
    textAlign: 'center',
};

const assetCards: IListAssetCardsProps = {
    cards: [
        {
            name: 'VND',
            amount: 50000000,
            icon: mkaIcon,
        },
        {
            name: 'VNDC',
            amount: 60878778,
            icon: vndcIcon,
        },
        {
            name: 'Ký quỹ',
            amount: 5000000,
            icon: kyQuyIcon,
        },
    ],
};

interface ITransactionProps {
    txn_hash: string;
    time: string;
    investor: string;
    email: string;
    source_hash: string;
    source: string;
    target_hash: string;
    target: string;
    exchange_type: 'invest' | 'upgrade';
    currency: 'VND' | 'VNDC';
    amount: number;
    status: 'success' | 'pending' | 'canceled';
}

const transactions: ITransactionProps[] = [
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        investor: 'Tri Nguyen',
        email: 'kevin@gmail.com',
        source_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        source: 'tring',
        target_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        target: 'Thành viên',
        exchange_type: 'invest',
        currency: 'VND',
        amount: 5000000,
        status: 'success',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        investor: 'Tri Nguyen',
        email: 'kevin@gmail.com',
        source_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        source: 'tring',
        target_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        target: 'Thành viên',
        exchange_type: 'upgrade',
        currency: 'VND',
        amount: 5000000,
        status: 'pending',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        investor: 'Tri Nguyen',
        email: 'kevin@gmail.com',
        source_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        source: 'tring',
        target_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        target: 'Thành viên',
        exchange_type: 'invest',
        currency: 'VNDC',
        amount: 5000000,
        status: 'canceled',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        investor: 'Tri Nguyen',
        email: 'kevin@gmail.com',
        source_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        source: 'tring',
        target_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        target: 'Thành viên',
        exchange_type: 'upgrade',
        currency: 'VND',
        amount: 5000000,
        status: 'pending',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        investor: 'Tri Nguyen',
        email: 'kevin@gmail.com',
        source_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        source: 'tring',
        target_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        target: 'Thành viên',
        exchange_type: 'invest',
        currency: 'VNDC',
        amount: 5000000,
        status: 'success',
    },
    {
        txn_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        time: '1 ngày trước',
        investor: 'Tri Nguyen',
        email: 'kevin@gmail.com',
        source_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        source: 'tring',
        target_hash: '0xa6d97a87a7a97a97ad7779c977b7b',
        target: 'Thành viên',
        exchange_type: 'invest',
        currency: 'VNDC',
        amount: 500000,
        status: 'canceled',
    },
];

const SuccessTag = () => (
    <Tag
        color='#e3f6eb'
        style={{
            ...TagStyles,
            color: '#044b22',
        }}
    >
        Thành công
    </Tag>
);

const PendingTag = () => (
    <Tag
        color='#fff1c7'
        style={{
            ...TagStyles,
            color: '#9f7400',
        }}
    >
        Chờ duyệt
    </Tag>
);

const CanceledTag = () => (
    <Tag
        color='#ffcccc'
        style={{
            ...TagStyles,
            color: '#8c1212',
        }}
    >
        Đã hủy
    </Tag>
);

const HashString = (hash: string) => {
    const renderString = `${hash.slice(0, 8)}...${hash.slice(
        hash.length - 3,
        hash.length,
    )}`;
    return <>{renderString}</>;
};

const SubCell = (text: string, highLight = false) => {
    const style = {
        fontSize: 10.5,
        color: highLight ? '#084ec3' : '#6c757d',
    };
    return <Text style={style}>{text}</Text>;
};

const InvestCell = (text: 'invest' | 'upgrade') => {
    const style = {
        color: '#084ec3',
    };
    return (
        <Text style={style}>{text === 'invest' ? 'Đầu tư' : 'Nâng cấp'}</Text>
    );
};

const columns = [
    {
        title: 'Txn Hash',
        dataIndex: 'txn_hash',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ITransactionProps) => (
            <>
                {HashString(text)}
                <br />
                {SubCell(record.time, true)}
            </>
        ),
    },
    {
        title: 'Nhà đầu tư',
        dataIndex: 'investor',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ITransactionProps) => (
            <>
                {text}
                <br />
                {SubCell(record.email)}
            </>
        ),
    },
    {
        title: 'Địa chỉ nguồn',
        dataIndex: 'source_hash',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ITransactionProps) => (
            <>
                {HashString(text)}
                <br />
                {SubCell(record.source)}
            </>
        ),
    },
    {
        title: 'Địa chỉ nhận',
        dataIndex: 'target_hash',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ITransactionProps) => (
            <>
                {HashString(text)}
                <br />
                {SubCell(record.target)}
            </>
        ),
    },
    {
        title: 'Loại giao dịch',
        dataIndex: 'exchange_type',
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: InvestCell,
    },
    {
        title: 'Số tiền',
        dataIndex: 'amount',
        width: 130,
        // eslint-disable-next-line react/display-name
        render: (amount: number, record: ITransactionProps) => (
            <Text style={{ color: '#e76200' }}>
                {getCommaNumber(amount)}
                {record.currency}
            </Text>
        ),
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        // eslint-disable-next-line react/display-name
        render: (status: 'success' | 'pending' | 'canceled') => {
            switch (status) {
                case 'success':
                    return <SuccessTag />;
                case 'pending':
                    return <PendingTag />;
                case 'canceled':
                    return <CanceledTag />;
            }
        },
    },
];

const TransactionHistoryWrapper = styled.div`
    && {
        table {
            padding-left: 20px;
            padding-top: 20px;
        }
        .ant-table-cell {
            line-height: 1;
            font-size: 18px !important;
        }
    }
`;

const Transaction = () => {
    return (
        <DashboardLayout>
            <Space size='middle' direction='vertical' style={{ width: '100%' }}>
                <AssetCardsListWrapper>
                    <List.AssetCards {...assetCards} />
                </AssetCardsListWrapper>
                <Form.AdvancedSearch />
                <TransactionHistoryWrapper>
                    <Table.Transaction
                        dataSource={transactions}
                        columns={columns}
                    />
                </TransactionHistoryWrapper>
            </Space>
        </DashboardLayout>
    );
};

export default Transaction;
