import styled from 'styled-components';

export const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
`;

export const ListItem = styled.div`
    padding: 10px;
    width: 33.33%;
`;
