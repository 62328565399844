import { Card } from 'components';
import { List, ListItem } from './style';

const AssetCards = ({ cards }: IListAssetCardsProps) => {
    return (
        <List>
            {cards.map(props => (
                <ListItem key={props.name}>
                    <Card.AssetCard {...props} />
                </ListItem>
            ))}
        </List>
    );
};

export default AssetCards;
