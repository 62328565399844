import { LoginPage, DashboardPage, TransactionPage } from 'pages';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { UserProvider } from 'contexts';
import { PublicRoute } from 'core';
import { LOGIN, TRANSACTION, DASHBOARD, HOME } from 'paths';

function App() {
    return (
        <UserProvider>
            <Router>
                <Switch>
                    <PublicRoute
                        exact
                        path={DASHBOARD}
                        component={DashboardPage}
                        // roles={[Role.Admin]}
                    />
                    <PublicRoute
                        exact
                        path={TRANSACTION}
                        component={TransactionPage}
                        // roles={[Role.Admin]}
                    />
                    <PublicRoute
                        exact
                        path={LOGIN}
                        component={LoginPage}
                        restricted
                    />
                    <Route exact path={HOME}>
                        <Redirect to={DASHBOARD} />
                    </Route>
                </Switch>
            </Router>
        </UserProvider>
    );
}

export default App;
