import styled, { keyframes } from 'styled-components';

// Create the keyframes
const load = keyframes`
    0% {
      left: 0;
      width: 0px;
    }
    50% {
      left: 100px;
      width: 100px;
    }
    100% {
      left: 200px;
      width: 0px;
    }
`;

// Styling container div
const Container = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

// Styling progress bar
const ProgressBar = styled.div`
    position: relative;
    width: 200px;
    min-height: 8px;
    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 200px;
        min-height: 8px;
        background-color: #eee;
    }
    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 30px;
        min-height: 8px;
        background-color: #ffcb00;
        animation: ${load} 1s linear infinite;
    }
`;

const Loading = () => (
    <Container>
        <ProgressBar />
    </Container>
);

export default Loading;
