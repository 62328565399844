import React, { useState } from 'react';

interface IThemeConfig {
    headerDark: boolean;
    bodyDark: boolean;
    footerDark: boolean;
}

const GlobalContext = React.createContext({
    theme: {
        headerDark: false,
        bodyDark: false,
        footerDark: false,
    },
    changeTheme: (theme: IThemeConfig) => {
        console.log(theme);
    },
});

const defaultThemeConfig = {
    headerDark: false,
    bodyDark: false,
    footerDark: false,
};

const GlobalProvider = ({ children }: any) => {
    const [theme, setTheme] = useState<IThemeConfig>(defaultThemeConfig);

    const changeTheme = (themeConfig: IThemeConfig = defaultThemeConfig) => {
        setTheme({
            ...themeConfig,
        });
    };

    return (
        <GlobalContext.Provider
            value={{
                theme,
                changeTheme,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContext;
export { GlobalProvider };
