import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 0px 8px 7.6px 1.4px rgba(123, 124, 1, 0.08);
    background-color: #ffffff;
    height: 100%;
    padding: 18px 16px;
`;

export const Title = styled.div`
    font-size: 31px;
    font-weight: 600;
    color: #111111;
    padding-bottom: 16px;
    border-bottom: 1px solid #edf0f2;
    margin: 0 16px;
`;

export const Chart = styled.div`
    height: 400px;
`;
