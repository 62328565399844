import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Container, Title, Chart } from './style';

const BarChartTable = ({ title, chart }: IBarChartTableProps) => {
    return (
        <Container>
            <Title>{title}</Title>
            <Chart>
                <ResponsiveContainer>
                    <BarChart
                        data={chart.data}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top' height={36} />
                        {chart.bars.map(props => (
                            <Bar {...props} key={props.name} />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </Chart>
        </Container>
    );
};

export default BarChartTable;
