import styled from 'styled-components';
import { Image } from 'antd';
import { ImageProps } from 'antd/lib/image';

export const Card = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 36px 30px 44px 37px;
    border-radius: 20px;
    box-shadow: 0px 8px 7.6px 1.4px rgba(123, 124, 1, 0.08);
    background-color: #ffffff;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Amount = styled.div`
    font-weight: bold;
    font-size: 30px;
    color: #111111;
`;

export const MutedText = styled.div`
    color: #6c757d;
    font-size: 20px;
    font-weight: 500;
`;

export const Icon = styled((props: ImageProps) => (
    <Image preview={false} {...props} />
))`
    width: 64px;
    height: 64px;
    border-radius: 50%;
`;
