import {
    ActionType,
    StatusType,
    authSelector,
    login,
    onChangeFieldValues,
} from 'redux/features/authSlice';
import { Button, Icons, Input } from 'core';
import {
    Container,
    Headline,
    StyledFormItem,
    FormWrapper,
    LogoWrapper,
} from './style';

import { Alert } from 'antd';
import { Form } from 'antd';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';

type FormLayout = 'horizontal' | 'inline' | 'vertical';
const formLayout: FormLayout = 'vertical';

const layout = {
    layout: formLayout,
};

const tailLayout = {};

const Login = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const authState = useSelector(authSelector);

    const isLoggingIn =
        authState.action === ActionType.Login &&
        authState.status === StatusType.Pending;
    const isLogInError =
        authState.action === ActionType.Login &&
        authState.status === StatusType.Error;
    const isLogInSuccess =
        authState.action === ActionType.Login &&
        authState.status === StatusType.Success;

    if (isLogInSuccess) {
        window.location.reload();
    }

    const onFinish = (values: { username: string; password: string }) => {
        dispatch(login(values));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Container>
            <LogoWrapper>
                <Icons.LogoVertical />
            </LogoWrapper>
            <FormWrapper>
                <Headline level={3}>Đăng nhập</Headline>
                <Form
                    {...layout}
                    form={form}
                    name='login'
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onChange={() => dispatch(onChangeFieldValues())}
                    requiredMark={false}
                >
                    <StyledFormItem
                        label='Số điện thoại/Email'
                        name='username'
                        rules={[
                            {
                                required: true,
                                message:
                                    'Vui lòng nhập số điện thoại hoặc e-mail!',
                            },
                        ]}
                    >
                        <Input.Text />
                    </StyledFormItem>
                    <StyledFormItem
                        label='Mật khẩu'
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: 'Mật khẩu không được để trống',
                            },
                        ]}
                    >
                        <Input.Password />
                    </StyledFormItem>
                    {isLogInError && (
                        <Alert type='error' message={authState.error} />
                    )}
                    <Form.Item {...tailLayout} style={{ marginTop: 32 }}>
                        <Button.Primary loading={isLoggingIn} htmlType='submit'>
                            Đăng nhập
                        </Button.Primary>
                    </Form.Item>
                </Form>
            </FormWrapper>
        </Container>
    );
};

export default Login;
